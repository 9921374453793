import React, { useEffect, useRef, useState } from 'react'
import Footer from 'examples/Footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftInput from 'components/SoftInput';
import SoftButton from 'components/SoftButton';
import Alert from "assets/Custom_alert/Custom_Alert";
import $ from 'jquery';
import 'jquery-validation';
import axios from 'axios';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Get_All_body_types_Data_Api } from 'Api/Api';
import { Get_All_Interested_topic_Data_Api } from 'Api/Api';
import { Add_Host_Api } from 'Api/Api';
import { TagsInput } from 'react-tag-input-component';
import SoftAvatar from 'components/SoftAvatar';
import { i_want_DefaultSelected_Api } from 'Api/Api';
import { i_am_DefaultSelected_Api } from 'Api/Api';
import { useNavigate } from 'react-router-dom';

const animatedComponents = makeAnimated();

const Add = () => {
    const [msg, setmsg] = useState({ msg: '', status: '' });
    const formRef = useRef(null);
    const [Body_Type_Options, setBody_Type_Options] = useState()
    const [Interested_Topic_Options, setInterested_Topic_Options] = useState()
    const [Interested_Topic, setInterested_Topic] = useState()
    const [Body_Type, setBody_Type] = useState()
    const [IamSelected, setIamSelected] = useState([]);
    const [I_wantSelected, setI_wantSelected] = useState([]);
    const [I_want_youSelected, setI_want_youSelected] = useState([]);
    const [PreviewImage, setPreviewImage] = useState();
    const [Images, setImages] = useState();
    const navigate = useNavigate();
    const GetBodyTypedata = () => {
        var config = {
            method: 'post',
            url: `${Get_All_body_types_Data_Api}`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
        };
        axios(config)
            .then((response) => {
                let data = response.data.data;
                let All_data = [];
                for (const key in data) {
                    All_data.push({ value: data[key].body_types, label: data[key].body_types });
                }
                setBody_Type_Options(All_data);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const GetInterested_Topic = () => {
        var config = {
            method: 'post',
            url: `${Get_All_Interested_topic_Data_Api}`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
        };
        axios(config)
            .then((response) => {
                let data = response.data.data;
                let All_data = [];
                for (const key in data) {
                    All_data.push({ value: data[key].interested_topic, label: data[key].interested_topic })
                }
                setInterested_Topic_Options(All_data)
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const i_am_DefaultSelected = () => {
        var config = {
            method: 'post',
            url: `${i_am_DefaultSelected_Api}`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
        };
        axios(config)
            .then((response) => {
                let data = response.data.data;
                let All_data = [];
                for (const key in data) {
                    All_data.push(data[key].tag)
                }
                setIamSelected(All_data)
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const i_want_DefaultSelected = () => {
        var config = {
            method: 'post',
            url: `${i_want_DefaultSelected_Api}`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
        };
        axios(config)
            .then((response) => {
                let data = response.data.data;
                let All_data = [];
                for (const key in data) {
                    All_data.push(data[key].tag)
                }
                setI_wantSelected(All_data)
            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        GetBodyTypedata();
        GetInterested_Topic();
        i_am_DefaultSelected();
        i_want_DefaultSelected();
    }, []);
    $(formRef.current).validate({
        rules: {
            username: "required",
            email: {
                required: true,
                email: true
            },
            password: "required",
            country: "required",
            age: "required",
            attrective_part: "required",
            body_types: "required",
            job: "required",
            education: "required",
            intrested_topics: "required",
            Profile_Image: "required",
        },
        messages: {
            username: "Please enter a username",
            email: {
                required: "Please enter a Email",
                email: "Please enter Valid Email"
            },
            password: "Please enter a Password",
            country: "Please enter a Country",
            age: "Please enter a Age",
            attrective_part: "Please enter a Attrective Parts",
            body_types: "Please enter a Body Type",
            job: "Please enter a Job",
            education: "Please enter a Education",
            intrested_topics: "Please enter a Intrested Topics",
            Profile_Image: "Please Select Profile Image",
        },
        errorPlacement: function (error, element) {
            error.insertAfter($(element).closest(".newerror"));
        },
        submitHandler: (form) => {
            let SendFormData = new FormData(form);
            var config = {
                method: 'post',
                url: `${Add_Host_Api}`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                data: SendFormData,
            };
            axios(config)
                .then((response) => {
                    if (response.data.success === true) {
                        setmsg({ msg: response.data.message, status: 'success' });
                        setTimeout(()=>{
                            navigate('/admin/Host/View');
                        }, 2000);
                    } else {
                        setmsg({ msg: response.data.message, status: 'error' });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    });
    const intrested_topics_Function = (e) => {
        setInterested_Topic(e);
    }
    const Body_Type_Function = (e) => {
        setBody_Type(e);
    }
    const SingleImageHandler = (e) => {
        setImages(e.target.files[0]);
        if(e.target.files[0]){
            setPreviewImage(URL.createObjectURL(e.target.files[0]));
        } else {
            setPreviewImage(null);
        }
    }
    return (
        <>
            {msg.msg && (
                <>
                    <div className="alert-container">
                        <Alert msg={msg.msg} status={msg.status} />
                    </div>
                    <div className='d-none'>
                        {setTimeout(() => {
                            setmsg({ msg: '', status: '' })
                        }, 3000)}
                    </div>
                </>
            )}
            <DashboardLayout>
                <DashboardNavbar />
                <div className='container min-vh-For_Footer' style={{ paddingTop: '2%' }}>
                    <div className='row justify-content-center'>
                        <div className='col-10'>
                            <SoftBox component="form" role="form" ref={formRef} id="myForm" className="d-flex flex-wrap">
                                <div className='col-12 px-2'>
                                    <SoftBox className="newerror">
                                        <h5>Profile Image</h5>
                                        <SoftAvatar
                                            src={PreviewImage ? `${PreviewImage}` : 'https://annemariesegal.files.wordpress.com/2017/06/img_0422-linkedin-size-smiling-man-in-suit.png?w=840&h=831'}
                                            alt="profile-image"
                                            variant="rounded"
                                            size="xl"
                                            shadow="sm"
                                            className="mb-3"
                                        />
                                        <SoftButton variant="gradient" className="me-3 position-relative" color="secondary">
                                            <input type="file" name="Profile_Image" className="custom_Upload_Images" onChange={SingleImageHandler} />
                                            Choose Image
                                        </SoftButton>
                                    </SoftBox>
                                </div>
                                <div className='col-6 px-2'>
                                    <SoftBox className="newerror">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Username
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="text" placeholder="username" name="username" />
                                    </SoftBox>
                                </div>
                                <div className='col-6 px-2'>
                                    <SoftBox className="newerror">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Email
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="text" placeholder="email" name="email" />
                                    </SoftBox>
                                </div>
                                <div className='col-6 px-2'>
                                    <SoftBox className="newerror">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Password
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="text" placeholder="password" name="password" />
                                    </SoftBox>
                                </div>
                                <div className='col-6 px-2'>
                                    <SoftBox className="newerror">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Country
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="text" placeholder="country" name="country" />
                                    </SoftBox>
                                </div>
                                <div className='col-6 px-2'>
                                    <SoftBox className="newerror">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Age
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="number" placeholder="age" name="age" />
                                    </SoftBox>
                                </div>
                                <div className='col-6 px-2'>
                                    <SoftBox className="newerror">
                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                            I am
                                        </SoftTypography>
                                        <div className='Custom_tag_Input'>
                                            <TagsInput
                                                value={IamSelected}
                                                onChange={setIamSelected}
                                                placeHolder="enter I Am"
                                            />
                                        </div>
                                        <input value={JSON.stringify(IamSelected)} name="i_am" className='d-none' />
                                    </SoftBox>
                                </div>
                                <div className='col-6 px-2'>
                                    <SoftBox className="newerror">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Attrective Parts
                                            </SoftTypography>
                                        </SoftBox>
                                        <div className='Custom_tag_Input'>
                                            <SoftInput type="text" placeholder="Attrective Parts" name="attrective_part" />
                                        </div>
                                    </SoftBox>
                                </div>
                                <div className='col-6 px-2'>
                                    <SoftBox className="newerror">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Job
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="text" placeholder="job" name="job" />
                                    </SoftBox>
                                </div>
                                <div className='col-6 px-2'>
                                    <SoftBox className="newerror">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Education
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="text" placeholder="education" name="education" />
                                    </SoftBox>
                                </div>
                                <div className='col-6 px-2'>
                                    <SoftBox className="newerror">
                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                            I Want
                                        </SoftTypography>
                                        <div className='Custom_tag_Input'>
                                            <TagsInput
                                                value={I_wantSelected}
                                                onChange={setI_wantSelected}
                                                placeHolder="enter I Want"
                                            />
                                        </div>
                                    </SoftBox>
                                    <input value={JSON.stringify(I_wantSelected)} name="i_want" className='d-none' />
                                </div>
                                <div className='col-6 px-2'>
                                    <SoftBox className="newerror">
                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                            I Want You
                                        </SoftTypography>
                                        <div className='Custom_tag_Input'>
                                            <TagsInput
                                                value={I_want_youSelected}
                                                onChange={setI_want_youSelected}
                                                placeHolder="enter I Want You"
                                            />
                                        </div>
                                    </SoftBox>
                                    <input value={JSON.stringify(I_want_youSelected)} name="i_want_you" className='d-none' />
                                </div>
                                <div className='col-6'></div>
                                <div className='col-6 px-2'>
                                    <SoftBox className="newerror">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Intrested Topics
                                            </SoftTypography>
                                        </SoftBox>
                                        <Select
                                            onChange={(e) => intrested_topics_Function(e)}
                                            name='intrested_topics'
                                            className='input_Dropdown'
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            isMulti
                                            options={Interested_Topic_Options}
                                        />
                                    </SoftBox>
                                </div>
                                <div className='col-6 px-2'>
                                    <SoftBox className="newerror">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Body Type
                                            </SoftTypography>
                                        </SoftBox>
                                        <Select
                                            name='body_types'
                                            className='input_Dropdown'
                                            onChange={(e) => Body_Type_Function(e)}
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            isMulti
                                            options={Body_Type_Options}
                                        />
                                    </SoftBox>
                                </div>
                                <SoftBox mt={2} mb={1} className="col-12 text-center">
                                    <SoftButton variant="gradient" type="submit" color="info">
                                        Add Host
                                    </SoftButton>
                                </SoftBox>
                            </SoftBox>
                            <Footer />
                        </div>
                    </div>
                </div>
            </DashboardLayout>
        </>
    )
}

export default Add;