/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react


Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';


import SoftBox from "components/SoftBox";
import DatePicker from "react-datepicker";


import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";
import PersonIcon from '@mui/icons-material/Person';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Soft UI Dashboard React context
import {
  useSoftUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

// Images
import team2 from "assets/images/team-2.jpg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import { Admin_Login_Path } from "Api/Routes";
import { Dashboard_profile_path } from "Api/Routes";
import { Settings_path } from "Api/Routes";
import { GetAll_Host_Pending_Amount_Api } from "Api/Api";
import axios from "axios"
import Alert from "assets/Custom_alert/Custom_Alert";
import { Pay_To_Host_Api } from "Api/Api";
import { GetHostEarningReportWithFilter_Api } from "Api/Api";
import html2canvas from 'html2canvas';
import jsPDF from "jspdf";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const [msg, setmsg] = useState({ msg: '', status: '' });
  const [Total_Pending_Amount, setTotal_Pending_Amount] = useState(0);
  const [FromDate, setFromDate] = useState(null);
  const [ToDate, setToDate] = useState(null);
  const [ReportData, setReportData] = useState([]);
  const [Report_Time, setReport_Time] = useState(null);
  const [display_Properties, setdisplay_Properties] = useState("none");

  // useEffect(() => {
  //   const authToken = JSON.parse(localStorage.getItem('authToken'));
  //   const data = JSON.parse(localStorage.getItem('data'));
  //   if(authToken){
  //     setIsLogin(true);
  //     setDatas(data);
  //   }
  // }, []);
  useEffect(() => {

    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }
    window.addEventListener("scroll", handleTransparentNavbar);
    handleTransparentNavbar();

    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);
  const GetHostEarningAmount = () => {
    var config = {
      method: 'post',
      url: `${GetAll_Host_Pending_Amount_Api}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      },
    };
    axios(config)
      .then((response) => {
        setTotal_Pending_Amount(response.data.data.Total_Pending_Amount);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const PayToHost_Function = () => {
    var config = {
      method: 'post',
      url: `${Pay_To_Host_Api}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      },
    };
    axios(config)
      .then((response) => {
        if (response.data.success == true) {
          setmsg({ msg: response.data.message, status: 'success' });
          $('#HostpaymentModal .pending_payment_modal_close').click();
        } else {
          setmsg({ msg: response.data.message, status: 'error' });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const FromDateComponent = () => {
    const handleDateChange = (date) => {
      setFromDate(date);
    };
    return (
      <div className="col-8">
        <DatePicker
          selected={FromDate}
          onChange={handleDateChange}
          dateFormat="yyyy-MM-dd"
          placeholderText="Select Date"
          className="form-control"
        />
      </div>
    );
  };
  const ToDateComponent = () => {
    const handleDateChange = (date) => {
      setToDate(date);
    };
    return (
      <div>
        <DatePicker
          selected={ToDate}
          onChange={handleDateChange}
          dateFormat="yyyy-MM-dd"
          placeholderText="Select Date"
          className="form-control"
        />
      </div>
    );
  };
  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        image={<img src={team2} alt="person" />}
        title={["New message", "from Laur"]}
        date="13 minutes ago"
        onClick={handleCloseMenu}
      />
      <NotificationItem
        image={<img src={logoSpotify} alt="person" />}
        title={["New album", "by Travis Scott"]}
        date="1 day"
        onClick={handleCloseMenu}
      />
      <NotificationItem
        color="secondary"
        image={
          <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
            payment
          </Icon>
        }
        title={["", "Payment successfully completed"]}
        date="2 days"
        onClick={handleCloseMenu}
      />
    </Menu>
  );
  const Date_Filter_Function = (e) => {
    var config = {
      method: 'post',
      url: `${GetHostEarningReportWithFilter_Api}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      },
      data: {
        startDate: FromDate,
        endDate: ToDate
      }
    };
    axios(config)
      .then((response) => {
        setReport_Time(response.data.data.Report_Time)
        setReportData(response.data.data.data)
        setdisplay_Properties("block")
        generatePDF();
        setdisplay_Properties("none")
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const PDFcontent = () => {
    return (
      <div >

        <div id="HostEarningReportPDFOutput" className="a4-page">
          <h3 className="col-12">
            Host Earning On  :- {Report_Time}
          </h3>
          <div>
            <form>
              <table className="Report_table" border="1">
                <thead>
                  <tr className="Report_td_tr Report_th">
                    <th>Host id</th>
                    <th>Duration</th>
                    <th>Host name</th>
                    <th>Host Coin</th>
                  </tr>
                </thead>
                <tbody>
                  {ReportData.map((row, index) => (
                    <tr className="Report_td_tr Report_tr" key={index}>
                      <td>{row.host_id}</td>
                      <td>{row.duration}</td>
                      <td>{row.host_name}</td>
                      <td>{row.Host_Coin}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </form>
          </div>
        </div>
      </div>
    )
  }
  const generatePDF = () => {
    const input = document.getElementById('HostEarningReportPDFOutput');
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgProperties = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        console.log(imgProperties)
        pdf.addImage(
          imgData,
          'PNG',
          0,
          0,
          pdfWidth,
          pdfHeight,
        );

        // pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('HostEarningReport.pdf');
      });
  };
  return (
    <>
      {msg.msg && (
        <>
          <div className="alert-container">
            <Alert msg={msg.msg} status={msg.status} />
          </div>
          <div className='d-none'>
            {setTimeout(() => {
              setmsg({ msg: '', status: '' })
            }, 3000)}
          </div>
        </>
      )}
      <AppBar
        position={absolute ? "absolute" : navbarType}
        color="inherit"
        sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
      >
        <Toolbar sx={(theme) => navbarContainer(theme)}>
          <SoftBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
            <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
          </SoftBox>
          {isMini ? null : (
            <SoftBox sx={(theme) => navbarRow(theme, { isMini })}>
              <SoftBox color={light ? "white" : "inherit"}>
                {/* <Link to="/authentication/sign-in">
                <IconButton sx={navbarIconButton} size="small">
                  <Icon
                    sx={({ palette: { dark, white } }) => ({
                      color: light ? white.main : dark.main,
                    })}
                  >
                    account_circle
                  </Icon>
                  <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    color={light ? "white" : "dark"}
                  >
                    Sign in
                  </SoftTypography>
                </IconButton>
              </Link> */}
                <IconButton
                  size="small"
                  color="inherit"
                  sx={navbarMobileMenu}
                  onClick={handleMiniSidenav}
                >
                  <Icon className={light ? "text-white" : "text-dark"}>
                    {miniSidenav ? "menu_open" : "menu"}
                  </Icon>
                </IconButton>
                <IconButton
                  size="small"
                  type="button" data-bs-toggle="modal" data-bs-target="#DownloadPDFModal"
                  onClick={GetHostEarningAmount}
                  color="inherit"
                  sx={navbarIconButton}
                >
                  <FileDownloadIcon />
                </IconButton>
                <IconButton
                  size="small"
                  type="button" data-bs-toggle="modal" data-bs-target="#HostpaymentModal"
                  onClick={GetHostEarningAmount}
                  color="inherit"
                  sx={navbarIconButton}
                >
                  <CurrencyRupeeIcon />
                </IconButton>
                <IconButton
                  size="small"
                  color="inherit"
                  sx={navbarIconButton}
                  onClick={() => window.location.href = Settings_path}
                >
                  <Icon>settings</Icon>
                </IconButton>
                <IconButton
                  size="small"
                  color="inherit"
                  sx={navbarMobileMenu}
                >
                  <Icon className={light ? "text-white" : "text-dark"}>
                    {miniSidenav ? "menu_open" : "menu"}
                  </Icon>
                </IconButton>
                <IconButton
                  size="small"
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={() => window.location.href = Dashboard_profile_path}
                >
                  <PersonIcon />
                </IconButton>
                <IconButton
                  size="small"
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenMenu}
                >
                  <Icon className={light ? "text-white" : "text-dark"}>notifications</Icon>
                </IconButton>
                <IconButton
                  size="small"
                  color="inherit"
                  sx={navbarIconButton}
                  onClick={() => { localStorage.clear(); window.location.href = Admin_Login_Path }}
                >
                  <Icon>logout</Icon>
                </IconButton>
                {renderMenu()}
              </SoftBox>
            </SoftBox>
          )}
        </Toolbar>
        <div className="modal fade text-dark" style={{ height: 'auto' }} id="HostpaymentModal" aria-labelledby="HostpaymentModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="HostpaymentModalLabel">Total Pending Amount is :- {Total_Pending_Amount}₹</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary pending_payment_modal_close" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary" onClick={PayToHost_Function}>Pay</button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade text-dark" style={{ height: 'auto' }} id="DownloadPDFModal" aria-labelledby="DownloadPDFModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="DownloadPDFModalLabel">Download Host Earning Report</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="col-12 d-flex align-items-center py-2">
                    <h6 className="mx-2 col-2">From :</h6> <FromDateComponent />
                  </div>
                  <div className="col-12 d-flex align-items-center">
                    <h6 className="mx-2 col-2">To :</h6> <ToDateComponent />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary Download_PDF_Modal_close" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary" onClick={Date_Filter_Function}>Download Report</button>
              </div>
            </div>
          </div>
        </div>
      </AppBar>
      <div style={{ display: `${display_Properties}` }}>
        <PDFcontent />
      </div>
    </>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
