
import { useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";


import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftProgress from "components/SoftProgress";

// Images
import logoXD from "assets/images/small-logos/logo-xd.svg";
import axios from "axios";
import { GetAllAppWiseData_Api } from "Api/Api";
export default function data() {
  const [Table_data, setTable_data] = useState([]);
  useEffect(() => {
    var config = {
      method: 'post',
      url: `${GetAllAppWiseData_Api}`,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    };
    axios(config)
      .then((response) => {
        let result = response.data.data.data;
        let DATA = [];
        for (const key in result) {
          DATA.push({
            app_id: (
              <h3>
                <SoftTypography variant="caption" ClassName="ps-3" color="text" fontWeight="medium">
                  {result[key].app_id}
                </SoftTypography>
              </h3>
            ),
            host: (
              <h3>
                <SoftTypography variant="caption" ClassName="ps-3" color="text" fontWeight="medium">
                  {result[key].host_app_count}
                </SoftTypography>
              </h3>
            ),
            user: (
              <h3>
                <SoftTypography variant="caption" ClassName="ps-3" color="text" fontWeight="medium">
                  {result[key].user_app_count}
                </SoftTypography>
              </h3>
            ),
          })
        }

        setTable_data(DATA)
      })
      .catch((error) => {
        console.log(error);
      });
    const avatars = (members) =>
      members.map(([image, name]) => (
        <Tooltip key={name} title={name} placeholder="bottom">
          <SoftAvatar
            src={image}
            alt="name"
            size="xs"
            sx={{
              border: ({ borders: { borderWidth }, palette: { white } }) =>
                `${borderWidth[2]} solid ${white.main}`,
              cursor: "pointer",
              position: "relative",

              "&:not(:first-of-type)": {
                ml: -1.25,
              },

              "&:hover, &:focus": {
                zIndex: "10",
              },
            }}
          />
        </Tooltip>
      ));
  }, [])

  return {
    columns: [
      { name: "app_id", align: "left" },
      { name: "host", align: "left" },
      { name: "user", align: "left" },
    ],

    rows: Table_data,
    // rows: [
    //   {
    //     companies: [logoXD, "Soft UI XD Version"],
    //     user: (
    //       <SoftTypography variant="caption" color="text" fontWeight="medium">
    //         $14,000
    //       </SoftTypography>
    //     ),
    //     completion: (
    //       <SoftBox width="8rem" textAlign="left">
    //         <SoftProgress value={60} color="info" variant="gradient" label={false} />
    //       </SoftBox>
    //     ),
    //   },
    //   // {
    //   //   companies: [logoAtlassian, "Add Progress Track"],
    //   //   user: (
    //   //     <SoftTypography variant="caption" color="text" fontWeight="medium">
    //   //       $3,000
    //   //     </SoftTypography>
    //   //   ),
    //   //   completion: (
    //   //     <SoftBox width="8rem" textAlign="left">
    //   //       <SoftProgress value={10} color="info" variant="gradient" label={false} />
    //   //     </SoftBox>
    //   //   ),
    //   // },
    //   // {
    //   //   companies: [logoSlack, "Fix Platform Errors"],
    //   //   user: (
    //   //     <SoftTypography variant="caption" color="text" fontWeight="medium">
    //   //       Not set
    //   //     </SoftTypography>
    //   //   ),
    //   //   completion: (
    //   //     <SoftBox width="8rem" textAlign="left">
    //   //       <SoftProgress value={100} color="success" variant="gradient" label={false} />
    //   //     </SoftBox>
    //   //   ),
    //   // },
    //   // {
    //   //   companies: [logoSpotify, "Launch our Mobile App"],
    //   //   user: (
    //   //     <SoftTypography variant="caption" color="text" fontWeight="medium">
    //   //       $20,500
    //   //     </SoftTypography>
    //   //   ),
    //   //   completion: (
    //   //     <SoftBox width="8rem" textAlign="left">
    //   //       <SoftProgress value={100} color="success" variant="gradient" label={false} />
    //   //     </SoftBox>
    //   //   ),
    //   // },
    //   // {
    //   //   companies: [logoJira, "Add the New Pricing Page"],
    //   //   user: (
    //   //     <SoftTypography variant="caption" color="text" fontWeight="medium">
    //   //       $500
    //   //     </SoftTypography>
    //   //   ),
    //   //   completion: (
    //   //     <SoftBox width="8rem" textAlign="left">
    //   //       <SoftProgress value={25} color="info" variant="gradient" label={false} />
    //   //     </SoftBox>
    //   //   ),
    //   // },
    //   // {
    //   //   companies: [logoInvesion, "Redesign New Online Shop"],
    //   //   user: (
    //   //     <SoftTypography variant="caption" color="text" fontWeight="medium">
    //   //       $2,000
    //   //     </SoftTypography>
    //   //   ),
    //   //   completion: (
    //   //     <SoftBox width="8rem" textAlign="left">
    //   //       <SoftProgress value={40} color="info" variant="gradient" label={false} />
    //   //     </SoftBox>
    //   //   ),
    //   // },
    // ],
  };
}
