import React, { useEffect, useRef, useState } from 'react'
import Footer from 'examples/Footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftInput from 'components/SoftInput';
import SoftButton from 'components/SoftButton';
import Alert from "assets/Custom_alert/Custom_Alert";
import $ from 'jquery';
import 'jquery-validation';
import axios from 'axios';
import SoftAvatar from 'components/SoftAvatar';
import { Add_app_package_Api } from 'Api/Api';
import { MenuItem, Select } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Add = () => {
    const navigate = useNavigate();
    const [msg, setmsg] = useState({ msg: '', status: '' });
    const formRef = useRef(null);
    const [PreviewImage, setPreviewImage] = useState();
    const [Images, setImages] = useState();
    const [data, setData] = useState({
        package_name: '',
        app_name: '',
        app_key: '',
        is_default: 0
    });
    useEffect(() => {
        $(formRef.current).validate({
            rules: {
                package_name: "required",
                app_name: "required",
                app_key: "required",
                is_default: "required",
            },
            messages: {
                package_name: "Please Enter Package Name",
                app_name: "Please Enter Application Name",
                app_key: "Please Enter Application Key",
                is_default: "Please Select Default",
            },
            errorPlacement: function (error, element) {
                error.insertAfter($(element).closest(".newerror"));
            },
            submitHandler: (form) => {
                var fileInput = $('#app_logo')[0];
                var file = fileInput.files[0];
                if (file) {
                    let SendFormData = new FormData(form);
                    var config = {
                        method: 'post',
                        url: `${Add_app_package_Api}`,
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                        },
                        data: SendFormData,
                    };
                    axios(config)
                        .then((response) => {
                            if (response.data.success === true) {
                                setmsg({ msg: response.data.message, status: 'success' });
                                setTimeout(() => {
                                    navigate('admin/app_package/View')
                                }, 2000);
                            } else {
                                setmsg({ msg: response.data.message, status: 'error' });
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else {
                    setmsg({ msg: "Please upload a app logo", status: 'error' });
                }
            },
        });
        $('.css-1i0az6v-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input').attr("style", "width:100% !important;padding:8px 28px 8px 12px !important");
        $('.css-1qlam7x-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root').attr("style", "padding:0 !important");
    }, []);
    const SingleImageHandler = (e) => {
        setImages(e.target.files[0]);
        if(e.target.files[0]){
            setPreviewImage(URL.createObjectURL(e.target.files[0]));
        } else {
            setPreviewImage(null);
        }
    }
    const changeHandler = (e) => {
        setData({...data, [e.target.name]: e.target.value});
    }
    return (
        <>
            {msg.msg && (
                <>
                    <div className="alert-container">
                        <Alert msg={msg.msg} status={msg.status} />
                    </div>
                    <div className='d-none'>
                        {setTimeout(() => {
                            setmsg({ msg: '', status: '' })
                        }, 3000)}
                    </div>
                </>
            )}
            <DashboardLayout>
                <DashboardNavbar />
                <div className='container min-vh-For_Footer' style={{ paddingTop: '2%' }}>
                    <div className='row justify-content-center'>
                        <div className='col-6'>
                            <SoftBox component="form" role="form" ref={formRef} id="myForm" className="d-flex flex-wrap">
                                <div className='col-12 px-2'>
                                    <SoftBox className="newerror">
                                        <h5>App Logo</h5>
                                        <SoftAvatar
                                            src={PreviewImage ? `${PreviewImage}` : 'https://annemariesegal.files.wordpress.com/2017/06/img_0422-linkedin-size-smiling-man-in-suit.png?w=840&h=831'}
                                            alt="profile-image"
                                            variant="rounded"
                                            size="xl"
                                            shadow="sm"
                                            className="mb-3"
                                        />
                                        <SoftButton variant="gradient" className="me-3 position-relative" color="secondary">
                                            <input type="file" name="image" id='app_logo' className="custom_Upload_Images" onChange={SingleImageHandler} />
                                            Choose Application Logo
                                        </SoftButton>
                                    </SoftBox>
                                    <SoftBox className="newerror">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Package Name
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="text" placeholder="Package Name" name="package_name" onChange={changeHandler} />
                                    </SoftBox>
                                    <SoftBox className="newerror">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Application Name
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="text" placeholder="Application Name" name="app_name" onChange={changeHandler} />
                                    </SoftBox>
                                    <SoftBox className="newerror">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Application Key
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="text" placeholder="Application Key" name="app_key" onChange={changeHandler} />
                                    </SoftBox>
                                    <SoftBox className="newerror">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Default
                                            </SoftTypography>
                                        </SoftBox>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name='is_default'
                                            value={data.is_default}
                                            onChange={changeHandler}
                                        >
                                            <MenuItem value={0}>No</MenuItem>
                                            <MenuItem value={1}>Yes</MenuItem>
                                        </Select>
                                    </SoftBox>
                                </div>
                                <SoftBox mt={2} mb={1} className="col-12 text-center">
                                    <SoftButton variant="gradient" type="submit" color="info">
                                        Add App Link
                                    </SoftButton>
                                </SoftBox>
                            </SoftBox>
                        </div>
                    </div>
                </div>
                <Footer />
            </DashboardLayout>
        </>
    )
}

export default Add;