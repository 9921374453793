
import Card from "@mui/material/Card";


import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import $ from 'jquery';
import 'jquery-validation';

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import curved6 from "assets/images/curved-images/curved14.jpg";
import axios from "axios";
import Alert from "assets/Custom_alert/Custom_Alert";
import { useEffect, useRef, useState } from "react";
import { Admin_Forget_Password_Main_Api } from "Api/Api";
import { Admin_Login_Url } from "Api/Routes";

const App = () => {
  const [msg, setmsg] = useState({ msg: '', status: '' });
  const formRef = useRef(null);
  useEffect(() => {
    $(formRef.current).validate({
      rules: {
        password: "required",
        c_password: "required",
        email: "required"
      },
      messages: {
        password: "Please enter a Password",
        c_password: "Please enter a Confirm Password",
        email: "Something Went Wrong"
      },
      errorPlacement: function (error, element) {
        error.insertAfter($(element).closest(".newerror"));
      },
      submitHandler: (form) => {
        let n_form = $(form).serialize();
        var config = {
          method: 'post',
          url: `${Admin_Forget_Password_Main_Api}`,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          data: n_form,
        };
        axios(config)
          .then((response) => {
            let result = response.data;
            if (result.success == true) {
              setmsg({ msg: result.message, status: 'success' });
              setTimeout(() => {
                window.location.href = Admin_Login_Url;
              }, 3000);
              localStorage.clear();
            } else {
              setmsg({ msg: result.message, status: 'error' });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
    });
  }, [])
  return (
    <>
      {msg.msg && (
        <>
          <div className="alert-container">
                        <Alert msg={msg.msg} status={msg.status} />
                    </div>
                    <div className='d-none'>
                        {setTimeout(() => {
                            setmsg({ msg: '', status: '' })
                        }, 3000)}
                    </div>
        </>
      )}
      <BasicLayout
        image={curved6}
      >
        <Card>
          <SoftBox p={3} mb={1} textAlign="center">
            <SoftTypography variant="h5" fontWeight="medium">
              Forget Password
            </SoftTypography>
          </SoftBox>
          <SoftBox pt={2} pb={3} px={3}>
            <SoftBox component="form" role="form" ref={formRef} id="myForm">
              <SoftBox className="newerror">
                <SoftInput type="text" placeholder="password" name="password" />
              </SoftBox>
              <SoftBox className="newerror" mt={2}>
                <SoftInput type="text" placeholder="Confrim password" name="c_password" />
              </SoftBox>
              <SoftBox className="newerror d-none">
                <SoftInput type="text" placeholder="Email" value={localStorage.getItem('email')} name="email" />
              </SoftBox>
              <SoftBox mt={2} mb={1}>
                <SoftButton variant="gradient" color="dark" type="submit" fullWidth>
                  Forget Password
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </BasicLayout>
    </>
  );
}

export default App;
