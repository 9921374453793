import React, { useEffect, useRef, useState } from 'react'
import Footer from 'examples/Footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftInput from 'components/SoftInput';
import SoftButton from 'components/SoftButton';
import Alert from "assets/Custom_alert/Custom_Alert";
import $ from 'jquery';
import 'jquery-validation';
import axios from 'axios';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { TagsInput } from 'react-tag-input-component';
import SoftAvatar from 'components/SoftAvatar';
import { Get_Single_Host_Data_Api, Update_Host_Data_Api, Get_All_body_types_Data_Api, Get_All_Interested_topic_Data_Api } from 'Api/Api';
import { host_Image_Path } from 'Api/Api';

const Update = () => {
    const animatedComponents = makeAnimated();
    const [msg, setmsg] = useState({ msg: '', status: '' });
    const [data, setdata] = useState({});
    const formRef = useRef(null);
    const [Body_Type_Options, setBody_Type_Options] = useState()
    const [Interested_Topic_Options, setInterested_Topic_Options] = useState()
    const [Interested_Topic, setInterested_Topic] = useState([]);
    const [Body_Type, setBody_Type] = useState([]);
    const [IamSelected, setIamSelected] = useState([]);
    const [I_wantSelected, setI_wantSelected] = useState([]);
    const [I_want_youSelected, setI_want_youSelected] = useState([]);
    const [PreviewImage, setPreviewImage] = useState();
    const [Images, setImages] = useState();
    const id = new URLSearchParams(window.location.search).get('id');
    const handleChange = (e) => {
        setdata((data) => ({
            ...data,
            [e.target.name]: e.target.value,
        }));
    }
    const GetBodyTypedata = () => {
        var config = {
            method: 'post',
            url: `${Get_All_body_types_Data_Api}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
        };
        axios(config)
            .then((response) => {
                let data = response.data.data;
                let All_data = [];
                for (const key in data) {
                    All_data.push({ value: data[key].body_types, label: data[key].body_types })
                }
                setBody_Type_Options(All_data)
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const GetInterested_Topic = () => {
        var config = {
            method: 'post',
            url: `${Get_All_Interested_topic_Data_Api}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
        };
        axios(config)
            .then((response) => {
                let data = response.data.data;
                let All_data = [];
                for (const key in data) {
                    All_data.push({ value: data[key].interested_topic, label: data[key].interested_topic });
                }
                setInterested_Topic_Options(All_data)
            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        GetData();
        GetBodyTypedata();
        GetInterested_Topic();
    }, []);
    const GetData = () => {
        var config = {
            method: 'post',
            url: `${Get_Single_Host_Data_Api}/${id}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
        };
        axios(config)
            .then((response) => {
                let result = response.data.data.data;
                setIamSelected(result.i_am)
                setI_wantSelected(result.i_want)
                setI_want_youSelected(result.i_want_you)
                setdata(result)
                setPreviewImage(result.image)
                let All_data = [];
                for (const key in result.body_types) {
                    All_data.push({ value: result.body_types[key], label: result.body_types[key] })
                }
                setBody_Type(All_data)
                All_data = [];
                for (const key in result.intrested_topics) {
                    All_data.push({ value: result.intrested_topics[key], label: result.intrested_topics[key] })
                }
                setInterested_Topic(All_data)
            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        $(formRef.current).validate({
            rules: {
                username: "required",
                email: {
                    required: true,
                    email: true
                },
                password: "required",
                country: "required",
                age: "required",
                attrective_part: "required",
                body_types: "required",
                job: "required",
                education: "required",
                intrested_topics: "required",
            },
            messages: {
                username: "Please enter a username",
                email: {
                    required: "Please enter a Email",
                    email: "Please enter Valid Email"
                },
                password: "Please enter a Password",
                country: "Please enter a Country",
                age: "Please enter a Age",
                attrective_part: "Please enter a Attrective Parts",
                body_types: "Please enter a Body Type",
                job: "Please enter a Job",
                education: "Please enter a Education",
                intrested_topics: "Please enter a Intrested Topics",
            },
            errorPlacement: function (error, element) {
                error.insertAfter($(element).closest(".newerror"));
            },
            submitHandler: (form) => {
                let n_form = new FormData(form);
                var config = {
                    method: 'post',
                    url: `${Update_Host_Data_Api}`,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    },
                    data: n_form,
                };
                axios(config)
                    .then((response) => {
                        if (response.data.success === true) {
                            setmsg({ msg: response.data.message, status: 'success' });
                        } else {
                            setmsg({ msg: response.data.message, status: 'error' });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            },
        });
    }, [])
    const intrested_topics_Function = (e) => {
        setInterested_Topic(e);
    }
    const Body_Type_Function = (e) => {
        setBody_Type(e);
    }
    const SingleImageHandler = (e) => {
        setImages(e.target.files[0]);
        if(e.target.files[0]){
            setPreviewImage(URL.createObjectURL(e.target.files[0]));
        } else {
            setPreviewImage(data.image);
        }
    }
    return (
        <>
            {msg.msg && (
                <>
                    <div className="alert-container">
                        <Alert msg={msg.msg} status={msg.status} />
                    </div>
                    <div className='d-none'>
                        {setTimeout(() => {
                            setmsg({ msg: '', status: '' })
                        }, 3000)}
                    </div>
                </>
            )}
            <DashboardLayout>
                <DashboardNavbar />
                <div className='container min-vh-For_Footer' style={{ paddingTop: '2%' }}>
                    <div className='row justify-content-center'>
                        <div className='col-10'>
                            <SoftBox component="form" role="form" ref={formRef} id="myForm" className="d-flex flex-wrap">
                                <div className='col-12 px-2'>
                                    <SoftBox className="newerror">
                                        <h5>Profile Image</h5>
                                        <SoftAvatar
                                            src={PreviewImage ? `${PreviewImage}` : 'https://annemariesegal.files.wordpress.com/2017/06/img_0422-linkedin-size-smiling-man-in-suit.png?w=840&h=831'}
                                            alt="profile-image"
                                            variant="rounded"
                                            size="xl"
                                            shadow="sm"
                                            className="mb-3"
                                        />
                                        <SoftButton variant="gradient" className="me-3 position-relative" color="secondary">
                                            <input type="file" name="Profile_Image" className="custom_Upload_Images" onChange={SingleImageHandler} />
                                            Choose Image
                                        </SoftButton>
                                    </SoftBox>
                                </div>
                                <div className='col-6 px-2'>
                                    <SoftBox className="newerror">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Username
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="text" placeholder="username" value={data.username} name="username" />
                                        <input className='d-none' type="text" placeholder="id" value={data.id} name="id" />
                                    </SoftBox>
                                </div>
                                <div className='col-6 px-2'>
                                    <SoftBox className="newerror">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Email
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="text" placeholder="email" value={data.email} disabled name="email" onChange={handleChange} />
                                    </SoftBox>
                                </div>
                                <div className='col-6 px-2'>
                                    <SoftBox className="newerror">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Country
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="text" placeholder="country" value={data.country} name="country" onChange={handleChange} />
                                    </SoftBox>
                                </div>
                                <div className='col-6 px-2'>
                                    <SoftBox className="newerror">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Age
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="number" placeholder="age" value={data.age} name="age" onChange={handleChange} />
                                    </SoftBox>
                                </div>
                                <div className='col-6 px-2'>
                                    <SoftBox className="newerror">
                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                            I am
                                        </SoftTypography>
                                        <div className='Custom_tag_Input'>
                                            <TagsInput
                                                value={IamSelected}
                                                onChange={setIamSelected}
                                                placeHolder="enter I Am"
                                            />
                                        </div>
                                        <input value={JSON.stringify(IamSelected)} name="i_am" className='d-none' onChange={handleChange} />
                                    </SoftBox>
                                </div>
                                <div className='col-6 px-2'>
                                    <SoftBox className="newerror">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Attrective Parts
                                            </SoftTypography>
                                        </SoftBox>
                                        <div className='Custom_tag_Input'>
                                            <SoftInput type="text" placeholder="Attrective Parts" value={data.attrective_part} name="attrective_part" onChange={handleChange} />
                                        </div>
                                    </SoftBox>
                                </div>
                                <div className='col-6 px-2'>
                                    <SoftBox className="newerror">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Job
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="text" placeholder="job" value={data.job} name="job" onChange={handleChange} />
                                    </SoftBox>
                                </div>
                                <div className='col-6 px-2'>
                                    <SoftBox className="newerror">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Education
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="text" placeholder="education" value={data.education} name="education" onChange={handleChange} />
                                    </SoftBox>
                                </div>
                                <div className='col-6 px-2'>
                                    <SoftBox className="newerror">
                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                            I Want
                                        </SoftTypography>
                                        <div className='Custom_tag_Input'>
                                            <TagsInput
                                                value={I_wantSelected}
                                                onChange={setI_wantSelected}
                                                placeHolder="enter I Want"
                                            />
                                        </div>
                                    </SoftBox>
                                    <input value={JSON.stringify(I_wantSelected)} name="i_want" className='d-none' />
                                </div>
                                <div className='col-6 px-2'>
                                    <SoftBox className="newerror">
                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                            I Want You
                                        </SoftTypography>
                                        <div className='Custom_tag_Input'>
                                            <TagsInput
                                                value={I_want_youSelected}
                                                onChange={setI_want_youSelected}
                                                placeHolder="enter I Want You"
                                            />
                                        </div>
                                    </SoftBox>
                                    <input value={JSON.stringify(I_want_youSelected)} name="i_want_you" className='d-none' />
                                </div>
                                <div className='col-6 px-2'>
                                    <SoftBox className="newerror">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Intrested Topics
                                            </SoftTypography>
                                        </SoftBox>
                                        <Select
                                            onChange={(e) => intrested_topics_Function(e)}
                                            name='intrested_topics'
                                            className='input_Dropdown'
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            isMulti
                                            options={Interested_Topic_Options}
                                            value={Interested_Topic}
                                        />
                                    </SoftBox>
                                </div>
                                <div className='col-6 px-2'>
                                    <SoftBox className="newerror">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Body Type
                                            </SoftTypography>
                                        </SoftBox>
                                        <Select
                                            name='body_types'
                                            className='input_Dropdown'
                                            onChange={(e) => Body_Type_Function(e)}
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            isMulti
                                            options={Body_Type_Options}
                                            value={Body_Type}
                                        />
                                    </SoftBox>
                                </div>
                                <SoftBox mt={2} mb={1} className="col-12 text-center">
                                    <SoftButton variant="gradient" type="submit" color="info">
                                        Update Host
                                    </SoftButton>
                                </SoftBox>
                            </SoftBox>
                        </div>
                    </div>
                </div>
                <Footer />
            </DashboardLayout>
        </>
    )
}

export default Update;