import React, { useEffect, useRef, useState } from 'react'
import Footer from 'examples/Footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftInput from 'components/SoftInput';
import SoftButton from 'components/SoftButton';
import Alert from "assets/Custom_alert/Custom_Alert";
import $ from 'jquery';
import 'jquery-validation';
import axios from 'axios';
import { Add_upi_Api } from 'Api/Api';

const Add = () => {
    const [msg, setmsg] = useState({ msg: '', status: '' });
    const formRef = useRef(null);
    useEffect(() => {
        $(formRef.current).validate({
            rules: {
                upi_id: "required",
            },
            messages: {
                upi_id: "Please Upi Id"
            },
            errorPlacement: function (error, element) {
                error.insertAfter($(element).closest(".newerror"));
            },
            submitHandler: (form) => {
                let n_form = $(form).serialize();
                var config = {
                    method: 'post',
                    url: `${Add_upi_Api}`,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    },
                    data: n_form,
                };
                axios(config)
                    .then((response) => {
                        if (response.data.success == true) {
                            setmsg({ msg: response.data.message, status: 'success' });
                        } else {
                            setmsg({ msg: response.data.message, status: 'error' });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            },
        });
    }, [])
    return (
        <>
            {msg.msg && (
                <>
                    <div className="alert-container">
                        <Alert msg={msg.msg} status={msg.status} />
                    </div>
                    <div className='d-none'>
                        {setTimeout(() => {
                            setmsg({ msg: '', status: '' })
                        }, 3000)}
                    </div>
                </>
            )
            }
            <DashboardLayout>
                <DashboardNavbar />
                <div className='container min-vh-For_Footer' style={{ paddingTop: '10%' }}>
                    <div className='row justify-content-center'>
                        <div className='col-4'>
                            <SoftBox component="form" role="form" ref={formRef} id="myForm">
                                <SoftBox className="newerror">
                                    <SoftBox mb={1} ml={0.5}>
                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                            Upi Id
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftInput type="text" placeholder="Upi Id" name="upi_id" />
                                </SoftBox>
                                <SoftBox mt={2} mb={1}>
                                    <SoftButton variant="gradient" type="submit" color="info" fullWidth>
                                        Add Upi Id
                                    </SoftButton>
                                </SoftBox>
                            </SoftBox>
                        </div>
                    </div>
                </div>
                <Footer />
            </DashboardLayout>
        </>
    )
}

export default Add;