const port = 3000;
// const url = `http://http://localhost:${port}`;
// const url = `http://localhost:3000`;
const url = `https://admin.omegames.in`;

const admin = ``;
const admin_url = `${url}/${admin}`;
const User = `/dashboard/User`;
const User_url = `${url}/${User}`;
const Plans = `/admin/plans`;
const Plans_url = `${url}/${Plans}`;
const Body_Types = `/admin/Body_Types`;
const Body_Types_url = `${url}/${Body_Types}`;
const i_am = `/admin/i_am`;
const i_am_url = `${url}/${i_am}`;
const i_want = `/admin/i_want`;
const i_want_url = `${url}/${i_want}`;
const Interested_topic = `/admin/Interested_topic`;
const Interested_topic_url = `${url}/${Interested_topic}`;
const Host = `/admin/Host`;
const Host_url = `${url}/${Host}`;
const Transaction = `/admin/Transaction`;
const Transaction_url = `${url}/${Transaction}`;
const Upi = `/admin/upi`;
const Upi_url = `${admin_url + admin}/upi`;
const Video = `/admin/Video`;
const Video_url = `${admin_url + admin}/Video`;
const app_link = `/admin/app_link`;
const app_link_url = `${admin_url + admin}/app_link`;
const app_package = `/admin/app_package`;
const app_package_url = `${admin_url + admin}/app_package`;

// Admin Routes
export const Admin_Login_Url = `${url}/login`;
export const Admin_Login_Path = `/login`;
export const Admin_ForgetPassword_Url = `${url}/ForgetPasswordEmail`;
export const Admin_ForgetPassword_Path = `/ForgetPasswordEmail`;
export const Admin_OtpVerification_Url = `${url}/OtpVerification`;
export const Admin_OtpVerification_Path = `/OtpVerification`;
export const Admin_ForgetPasswordMain_Url = `${url}/ForgetPasswordMain`;
export const Admin_ForgetPasswordMain_Path = `/ForgetPasswordMain`;
export const privacy_policy_Url = `${url}/privacy_policy`;
export const privacy_policy_Path = `/privacy_policy`;
export const Terms_And_Condition_Url = `${url}/Terms_And_Condition`;
export const Terms_And_Condition_Path = `/Terms_And_Condition`;
export const About_us_Url = `${url}/About_us`;
export const About_us_Path = `/About_us`;
export const Coin_History_Url = `${url}/Coin_History`;
export const Coin_History_Path = `/Coin_History`;

// Dashboard Routes
export const Dashboard_path = `${admin}/dashboard`;
export const Dashboard_url = `${url}/dashboard`;
export const Dashboard_profile_path = `/profile`;
export const Dashboard_profile__url = `${admin_url + admin}/profile`;
// export const ChangePassword_path = `/ChangePassword`;
// export const ChangePassword__url = `${admin_url + admin}/ChangePassword`;
export const Settings_path = `/Settings`;
export const Settings__url = `${admin_url + admin}/Settings`;
// export const User_ViewAll_path = `/User_ViewAll_Path`;
// export const User_ViewAll__url = `${admin_url + admin}/User_ViewAll_Path`;

// Video
export const Video_Add_path = `${Video}/add`;
export const Video_Add__url = `${Video_url}/add`;
export const Video_View_path = `${Video}/View`;
export const Video_View__url = `${Video_url}/View`;
export const Video_Update_path = `${Video}/Update`;
export const Video_Update__url = `${Video_url}/Update`;

// App Link
export const app_link_Add_path = `${app_link}/add`;
export const app_link_Add__url = `${app_link_url}/add`;
export const app_link_View_path = `${app_link}/View`;
export const app_link_View__url = `${app_link_url}/View`;
export const app_link_Update_path = `${app_link}/Update`;
export const app_link_Update__url = `${app_link_url}/Update`;

// App Link
export const app_package_Add_path = `${app_package}/add`;
export const app_package_Add__url = `${app_package_url}/add`;
export const app_package_View_path = `${app_package}/View`;
export const app_package_View__url = `${app_package_url}/View`;
export const app_package_Update_path = `${app_package}/Update`;
export const app_package_Update__url = `${app_package_url}/Update`;

// Admin Plan
export const Plan_Add_path = `${Plans}/add`;
export const Plan_Add__url = `${Plans_url}/add`;
export const Plan_View_path = `${Plans}/View`;
export const Plan_View__url = `${Plans_url}/View`;
export const Plan_Update_path = `${Plans}/Update`;
export const Plan_Update__url = `${Plans_url}/Update`;

// Admin Plan
export const Upi_Add_path = `${Upi}/add`;
export const Upi_Add__url = `${Upi_url}/add`;
export const Upi_View_path = `${Upi}/View`;
export const Upi_View__url = `${Upi_url}/View`;
export const Upi_Update_path = `${Upi}/Update`;
export const Upi_Update__url = `${Upi_url}/Update`;

// Admin Body Types
export const Body_Types_Add_path = `${Body_Types}/add`;
export const Body_Types_Add__url = `${Body_Types_url}/add`;
export const Body_Types_View_path = `${Body_Types}/View`;
export const Body_Types_View__url = `${Body_Types_url}/View`;
export const Body_Types_Update_path = `${Body_Types}/Update`;
export const Body_Types_Update__url = `${Body_Types_url}/Update`;

// Admin I Am 
export const i_am_Add_path = `${i_am}/add`;
export const i_am_Add__url = `${i_am_url}/add`;
export const i_am_View_path = `${i_am}/View`;
export const i_am_View__url = `${i_am_url}/View`;
export const i_am_Update_path = `${i_am}/Update`;
export const i_am_Update__url = `${i_am_url}/Update`;

// Admin I Want 
export const i_want_Add_path = `${i_want}/add`;
export const i_want_Add__url = `${i_want_url}/add`;
export const i_want_View_path = `${i_want}/View`;
export const i_want_View__url = `${i_want_url}/View`;
export const i_want_Update_path = `${i_want}/Update`;
export const i_want_Update__url = `${i_want_url}/Update`;

// Admin Interested topic
export const Interested_topic_Add_path = `${Interested_topic}/add`;
export const Interested_topic_Add__url = `${Interested_topic_url}/add`;
export const Interested_topic_View_path = `${Interested_topic}/View`;
export const Interested_topic_View__url = `${Interested_topic_url}/View`;
export const Interested_topic_Update_path = `${Interested_topic}/Update`;
export const Interested_topic_Update__url = `${Interested_topic_url}/Update`;

// Admin Host
export const Host_Add_path = `${Host}/add`;
export const Host_Add__url = `${Host_url}/add`;
export const Host_View_path = `${Host}/View`;
export const Host_View__url = `${Host_url}/View`;
export const Host_Update_path = `${Host}/Update`;
export const Host_Update__url = `${Host_url}/Update`;
export const Host_ViewImages_path = `${Host}/ViewImages`;
export const Host_ViewImages__url = `${Host_url}/ViewImages`;
export const View_Single_Host_Details_path = `${Host}/View_Single_Host_Details`;
export const View_Single_Host_Details__url = `${Host_url}/View_Single_Host_Details`;
export const Host_Call_History_path = `${Host}/Host_Call_History`;
export const Host_Call_History__url = `${Host_url}/Host_Call_History`;

// User url
export const View_All_path = `${User}/ViewAllUser`;
export const View_All_url = `${User_url}/ViewAllUser`;
export const View_Single_User_path = `${User}/ViewDetails`;
export const View_Single_User_url = `${User_url}/ViewDetails`;
export const User_Update_path = `${User}/Update`;
export const User_Update_url = `${User_url}/Update`;

// Transaction url 
export const User_Transactions_list_path = `${Transaction}/user_transaction_list`;
export const User_Transactions_list_url = `${Transaction_url}/user_transaction_list`;
export const Host_weekly_Payout_path = `${Transaction}/Host_weekly_Payout`;
export const Host_weekly_Payout_url = `${Transaction_url}/Host_weekly_Payout`;