import React, { useEffect, useRef, useState } from 'react'
import Footer from 'examples/Footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftInput from 'components/SoftInput';
import SoftButton from 'components/SoftButton';
import Alert from "assets/Custom_alert/Custom_Alert";
import $ from 'jquery';
import 'jquery-validation';
import axios from 'axios';
import makeAnimated from 'react-select/animated';
import SoftAvatar from 'components/SoftAvatar';
import { Update_Host_Data_Api } from 'Api/Api';
import { Get_Single_User_Api } from 'Api/Api';
import { Update_User_Data_Api } from 'Api/Api';

const Update = () => {
    const [msg, setmsg] = useState({ msg: '', status: '' });
    const [data, setdata] = useState({});
    const formRef = useRef(null);
    const [PreviewImage, setPreviewImage] = useState();
    const [Images, setImages] = useState();
    const id = new URLSearchParams(window.location.search).get('id');
    const handleChange = (e) => {
        setdata((data) => ({
            ...data,
            [e.target.name]: e.target.value,
        }));
    }
    useEffect(() => {
        GetData();
    }, []);
    const GetData = () => {
        var config = {
            method: 'post',
            url: `${Get_Single_User_Api}/${id}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
        };
        axios(config)
            .then((response) => {
                let result = response.data.data;
                setPreviewImage(result.image)
                setdata(result)
            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        $(formRef.current).validate({
            rules: {
                nickname: "required",
                age: "required",
                coins: "required",
                gender: "required",
            },
            messages: {
                nickname: "Please Enter nickname",
                age: "Please Enter age",
                coins: "Please Enter coins",
                gender: "Please Enter gender",
            },
            errorPlacement: function (error, element) {
                error.insertAfter($(element).closest(".newerror"));
            },
            submitHandler: (form) => {
                let n_form = new FormData(form);
                var config = {
                    method: 'post',
                    url: `${Update_User_Data_Api}`,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    },
                    data: n_form,
                };
                axios(config)
                    .then((response) => {
                        if (response.data.success === true) {
                            setmsg({ msg: response.data.message, status: 'success' });
                        } else {
                            setmsg({ msg: response.data.message, status: 'error' });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            },
        });
    }, [])
    const SingleImageHandler = (e) => {
        setImages(e.target.files[0]);
        setPreviewImage(URL.createObjectURL(e.target.files[0]));
    }
    return (
        <>
            {msg.msg && (
                <>
                    <div className="alert-container">
                        <Alert msg={msg.msg} status={msg.status} />
                    </div>
                    <div className='d-none'>
                        {setTimeout(() => {
                            setmsg({ msg: '', status: '' })
                        }, 3000)}
                    </div>
                </>
            )}
            <DashboardLayout>
                <DashboardNavbar />
                <div className='container min-vh-For_Footer' style={{ paddingTop: '2%' }}>
                    <div className='row justify-content-center'>
                        <div className='col-10'>
                            <SoftBox component="form" role="form" ref={formRef} id="myForm" className="d-flex flex-wrap">
                                <div className='col-12 px-2'>
                                    <SoftBox className="newerror">
                                        <h5>Profile Image</h5>
                                        <SoftAvatar
                                            src={PreviewImage ? `${PreviewImage}` : 'https://annemariesegal.files.wordpress.com/2017/06/img_0422-linkedin-size-smiling-man-in-suit.png?w=840&h=831'}
                                            alt="profile-image"
                                            variant="rounded"
                                            size="xl"
                                            shadow="sm"
                                            className="mb-3"
                                        />
                                        <SoftButton variant="gradient" className="me-3 position-relative" color="secondary">
                                            <input type="file" name="Profile_Image" className="custom_Upload_Images" onChange={SingleImageHandler} />
                                            Choose Image
                                        </SoftButton>
                                    </SoftBox>
                                </div>
                                <div className='col-6 px-2'>
                                    <SoftBox className="newerror">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Username
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="text" placeholder="nickname" value={data.nickname} name="nickname" onChange={handleChange} />
                                        <input className='d-none' type="text" placeholder="id" value={data.id} name="id" />
                                    </SoftBox>
                                </div>
                                <div className='col-6 px-2'>
                                    <SoftBox className="newerror">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Age
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="number" placeholder="age" value={data.age} name="age" onChange={handleChange} />
                                    </SoftBox>
                                </div>
                                <div className='col-6 px-2'>
                                    <SoftBox className="newerror">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Coins
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="number" placeholder="coins" value={data.coins} name="coins" onChange={handleChange} />
                                    </SoftBox>
                                </div>
                                <div className='col-6 px-2'>
                                    <SoftBox className="newerror">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Gender
                                            </SoftTypography>
                                        </SoftBox>
                                        <input type="radio" name="gender" value="male" checked={data.gender == "male" ? true : false} className='me-1' onChange={handleChange} />
                                        <label style={{ fontSize: '15px' }}>male</label>
                                        <input type="radio" name="gender" value="female" checked={data.gender == "female" ? true : false} className='ms-2 me-1' onChange={handleChange} />
                                        <label style={{ fontSize: '15px' }}>female</label>
                                    </SoftBox>
                                </div>
                                {/* <div className='col-6 px-2'>
                                    <SoftBox className="newerror">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Gender
                                            </SoftTypography>
                                        </SoftBox>
                                        <input type="radio" name="gender" value="male" checked={data.gender == "male" ? true : false} className='me-1' onChange={handleChange} />
                                        <label style={{ fontSize: '15px' }}>male</label>
                                        <input type="radio" name="gender" value="female" checked={data.gender == "female" ? true : false} className='ms-2 me-1' onChange={handleChange} />
                                        <label style={{ fontSize: '15px' }}>female</label>
                                    </SoftBox>
                                </div> */}
                                <SoftBox mt={2} mb={1} className="col-12 text-center">
                                    <SoftButton variant="gradient" type="submit" color="info">
                                        Update User
                                    </SoftButton>
                                </SoftBox>
                            </SoftBox>
                        </div>
                    </div>
                </div>
                <Footer />
            </DashboardLayout>
        </>
    )
}

export default Update;