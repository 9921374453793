
import Grid from "@mui/material/Grid";


import SoftBox from "components/SoftBox";


import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";

// Overview page components
import Header from "layouts/profile/components/Header";
import { Card } from "reactstrap";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import Alert from "assets/Custom_alert/Custom_Alert";
import $ from 'jquery';
import 'jquery-validation';
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Admin_getadminData_Api, Admin_UpdateProfile_Api } from "Api/Api";
import { Admin_Change_Password_Api } from "Api/Api";
const Overview = () => {
  const [msg, setmsg] = useState({ msg: '', status: '' });
  const [data, setdata] = useState({});
  const formRef = useRef(null);
  const ChangePasswordformRef = useRef(null);
  const id = JSON.parse(localStorage.getItem('data')).id;
  const GetData = () => {
    var config = {
      method: 'post',
      url: `${Admin_getadminData_Api}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      },
      data: { id: id }
    };
    axios(config)
      .then((response) => {
        setdata(response.data.data)
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    GetData();
    $(formRef.current).validate({
      rules: {
        id: "required",
        username: "required",
        email: "required",
        first_name: "required",
        last_name: "required",
        phone: { required: true, minlength: 10, maxlength: 10 },
      },
      messages: {
        id: "Something Went Wrong",
        username: "Please enter a Username",
        email: "Please enter a Email",
        first_name: "Please enter a First Name",
        last_name: "Please enter a Last Name",
        phone: {
          required: "Please enter a Phone Number",
          minlength: "Please enter 10 Digit Phone Number",
          maxlength: "Please enter 10 Digit Phone Number"
        },
      },
      errorPlacement: function (error, element) {
        error.insertAfter($(element).closest(".newerror"));
      },
      submitHandler: (form) => {
        let n_form = $(form).serialize();
        var config = {
          method: 'post',
          url: `${Admin_UpdateProfile_Api}`,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          },
          data: n_form,
        };
        axios(config)
          .then((response) => {
            if (response.data.success == true) {
              setmsg({ msg: response.data.message, status: 'success' });
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            } else {
              setmsg({ msg: response.data.message, status: 'error' });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
    });
  }, []);

  $(ChangePasswordformRef.current).validate({
    rules: {
      old_password: "required",
      new_password: "required",
      new_c_password: "required",
      email: "required",
    },
    messages: {
      old_password: "Please Enter old password",
      new_password: "Please Enter new password",
      new_c_password: "Please Enter new Confrim password",
      email: "Something Went Wrong"
    },
    errorPlacement: function (error, element) {
      error.insertAfter($(element).closest(".newerror"));
    },
    submitHandler: (form) => {
      let n_form = $(form).serialize();
      var config = {
        method: 'post',
        url: `${Admin_Change_Password_Api}`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
        data: n_form,
      };
      axios(config)
        .then((response) => {
          let result = response.data;
          if (result.success == true) {
            setmsg({ msg: result.message, status: 'success' });
          } else {
            setmsg({ msg: result.message, status: 'error' });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });
  const handleChange = (e) => {
    setdata((data) => ({
      ...data,
      [e.target.name]: e.target.value,
    }));
  }
  return (
    <>
      {msg.msg && (
        <>
          <div className="alert-container">
            <Alert msg={msg.msg} status={msg.status} />
          </div>
          <div className='d-none'>
            {setTimeout(() => {
              setmsg({ msg: '', status: '' })
            }, 3000)}
          </div>
        </>
      )}
      <DashboardLayout>
        <Header />
        <SoftBox mt={5} mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} xl={12} className="d-flex" >
              <Card className="p-4 col-8">
                <SoftBox component="form" role="form" ref={formRef} className="d-flex flex-wrap" id="myForm">
                  <div className="col-6 px-2">
                    <SoftBox className="newerror">
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          First Name
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput type="text" placeholder="First Name" name="first_name" value={data.first_name} onChange={handleChange} />
                    </SoftBox>
                  </div>
                  <div className="col-6 px-2">
                    <SoftBox className="newerror">
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Last Name
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput type="text" placeholder="Last Name" name="last_name" value={data.last_name} onChange={handleChange} />
                    </SoftBox>
                  </div>
                  <div className="col-6 px-2">
                    <SoftBox className="newerror">
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Username
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput type="text" placeholder="username" name="username" value={data.username} onChange={handleChange} />
                      <SoftBox className="d-none">
                        <SoftInput type="text" placeholder="id" name="id" value={data.id} />
                      </SoftBox>
                    </SoftBox>
                  </div>
                  <div className="col-6 px-2">
                    <SoftBox className="newerror">
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Email
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput type="text" disabled placeholder="email" name="email" value={data.email} onChange={handleChange} />
                    </SoftBox>
                  </div>
                  <div className="col-6 px-2">
                    <SoftBox className="newerror">
                      <SoftBox mb={1} ml={0.5}>
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Phone
                        </SoftTypography>
                      </SoftBox>
                      <SoftInput type="number" placeholder="phone" name="phone" value={data.phone} onChange={handleChange} />
                    </SoftBox>
                  </div>
                  <SoftBox mt={2} mb={1} className="col-12 text-center">
                    <SoftButton variant="gradient" type="submit" color="info">
                      Update Profile
                    </SoftButton>
                  </SoftBox>
                </SoftBox>
              </Card>
              <Card className="p-4 col-4 ms-3">
                <h3>Change Password</h3>
                <SoftBox component="form" role="form" ref={ChangePasswordformRef} id="myForm">
                  <SoftBox className="newerror">
                    <SoftBox mb={1} ml={0.5}>
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Old Password
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput type="text" placeholder="old password" name="old_password" />
                  </SoftBox>
                  <SoftBox className="newerror">
                    <SoftBox ml={0.5}>
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        New Password
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput type="text" placeholder="New password" name="new_password" />
                  </SoftBox>
                  <SoftBox className="newerror">
                    <SoftBox ml={0.5}>
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        New Confrim Password
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput type="text" placeholder="New Confrim password" name="new_c_password" />
                  </SoftBox>
                  <SoftBox className="newerror d-none">
                    <SoftBox ml={0.5}>
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Email
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput type="text" placeholder="Email" name="email" value={localStorage.getItem('email')} />
                  </SoftBox>
                  <SoftBox mt={2} mb={1}>
                    <SoftButton variant="gradient" type="submit" color="info" fullWidth>
                      Change Password
                    </SoftButton>
                  </SoftBox>
                </SoftBox>
              </Card>
            </Grid>
          </Grid>
        </SoftBox>
        <Footer />
      </DashboardLayout >
    </>
  );
}

export default Overview;
