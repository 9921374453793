import React from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = (prop) => {
    toast[prop.status](prop.msg)
    return (
        <>
            <div>
                <ToastContainer position='top-right' autoClose={3000} />
            </div>
        </>
    );
}

export default App;