/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react


Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";


import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";


import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Images
import burceMars from "assets/images/bruce-mars.jpg";
import curved0 from "assets/images/curved-images/curved0.jpg";


import SoftButton from "components/SoftButton";
import Alert from "assets/Custom_alert/Custom_Alert";
import $ from 'jquery';
import 'jquery-validation';
import axios from "axios";
import { Admin_getadminData_Api, Admin_ImageUpload_Api } from "Api/Api";

function Header() {
  const [msg, setmsg] = useState({ msg: '', status: '' });
  const [data, setdata] = useState({});
  const [profile_image, setProfile_image] = useState();
  const [Reveiew_image, setReveiew_image] = useState();
  const GetData = () => {
    var config = {
      method: 'post',
      url: `${Admin_getadminData_Api}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      },
      data: { id: JSON.parse(localStorage.getItem('data')).id }
    };
    axios(config)
      .then((response) => {
        setdata(response.data.data);
        setReveiew_image(response.data.data.profile_image);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    GetData();
  }, []);

  const ImageHandler = (e) => {
    setProfile_image(e.target.files[0]);
    if(e.target.files[0]){
      setReveiew_image(URL.createObjectURL(e.target.files[0]));
    } else {
      setReveiew_image(data.profile_image);
    }
  }
  const Image_Upload = () => {
    if (profile_image === "" || profile_image === null || profile_image === undefined) {
      setmsg({ msg: "Please Select Image", status: 'error' });
    }
    else {
      let n_form = new FormData();
      n_form.append('id', data.id)
      n_form.append('ProfileImage', profile_image)
      var config = {
        method: 'post',
        url: `${Admin_ImageUpload_Api}`,
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
        data: n_form
      };
      axios(config)
        .then((response) => {
          let result = response.data;
          if (result.success == true) {
            setReveiew_image(result.data.Profile_image);
            setmsg({ msg: result.message, status: 'success' });
          } else {
            setmsg({ msg: result.message, status: 'error' });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  return (
    <>
      {msg.msg && (
        <>
          <div className="alert-container">
                        <Alert msg={msg.msg} status={msg.status} />
                    </div>
                    <div className='d-none'>
                        {setTimeout(() => {
                            setmsg({ msg: '', status: '' })
                        }, 3000)}
                    </div>
        </>
      )}
      <SoftBox position="relative">
        <DashboardNavbar absolute light />
        <SoftBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="18.75rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.info.main, 0.6),
                rgba(gradients.info.state, 0.6)
              )}, url(${curved0})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        />
        <Card
          sx={{
            backdropFilter: `saturate(200%) blur(30px)`,
            backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
            boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
            position: "relative",
            mt: -8,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <SoftAvatar
                src={Reveiew_image ? `${Reveiew_image}` : 'https://annemariesegal.files.wordpress.com/2017/06/img_0422-linkedin-size-smiling-man-in-suit.png?w=840&h=831'}
                alt="profile-image"
                variant="rounded"
                size="xl"
                shadow="sm"
              />
            </Grid>
            <Grid item>
              <SoftBox height="100%" mt={0.5} lineHeight={1}>
                <SoftTypography variant="h5" fontWeight="medium">
                  {data.username}
                </SoftTypography>
                <SoftTypography variant="button" color="text" fontWeight="medium">
                  {data.email}
                </SoftTypography>
              </SoftBox>
            </Grid>
            <Grid item xs={12} md={4} lg={3} sx={{ ml: "auto" }}>
              <AppBar position="static">
                <SoftBox mt={2} mb={1} className="text-end">
                  <SoftButton variant="gradient" className="me-3 position-relative" color="secondary">
                    <input type="file" name="profile_image" className="custom_Upload_Images" onChange={ImageHandler} />
                    Choose Image
                  </SoftButton>
                  <SoftButton variant="gradient" type="submit" color="info" onClick={Image_Upload}>
                    Save
                  </SoftButton>
                </SoftBox>
              </AppBar>
            </Grid>
          </Grid>
        </Card>
      </SoftBox>
    </>
  );
}

export default Header;
