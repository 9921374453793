import React, { useEffect, useRef, useState } from 'react'
import Footer from 'examples/Footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftInput from 'components/SoftInput';
import SoftButton from 'components/SoftButton';
import Alert from "assets/Custom_alert/Custom_Alert";
import { Link } from 'react-router-dom';
import $ from 'jquery';
import 'jquery-validation';
import axios from 'axios';
import { Get_Single_i_want_Data_Api } from 'Api/Api';
import { Update_i_want_Data_Api } from 'Api/Api';

const Update = () => {
    const [msg, setmsg] = useState({ msg: '', status: '' });
    const [data, setdata] = useState({});
    const formRef = useRef(null);
    const id = new URLSearchParams(window.location.search).get('id');
    const GetData = () => {
        var config = {
            method: 'post',
            url: `${Get_Single_i_want_Data_Api}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
            data: { id: id }
        };
        axios(config)
            .then((response) => {
                setdata(response.data.data.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        GetData();
        $(formRef.current).validate({
            rules: {
                tag: "required",
                id: "required",
            },
            messages: {
                tag: "Please enter tag",
                id: "Something Went Wrong"
            },
            errorPlacement: function (error, element) {
                error.insertAfter($(element).closest(".newerror"));
            },
            submitHandler: (form) => {
                let n_form = $(form).serialize();
                var config = {
                    method: 'post',
                    url: `${Update_i_want_Data_Api}`,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    },
                    data: n_form,
                };
                axios(config)
                    .then((response) => {
                        if (response.data.success == true) {
                            setmsg({ msg: response.data.message, status: 'success' });
                        } else {
                            setmsg({ msg: response.data.message, status: 'error' });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            },
        });
    }, [])
    const handleChange = (e) => {
        setdata((data) => ({
            ...data,
            [e.target.name]: e.target.value,
        }));
    }
    return (
        <>
            {msg.msg && (
                <>
                    <div className="alert-container">
                        <Alert msg={msg.msg} status={msg.status} />
                    </div>
                    <div className='d-none'>
                        {setTimeout(() => {
                            setmsg({ msg: '', status: '' })
                        }, 3000)}
                    </div>
                </>
            )}
            <DashboardLayout>
                <DashboardNavbar />
                <div className='container min-vh-For_Footer' style={{ paddingTop: '10%' }}>
                    <div className='row justify-content-center'>
                        <div className='col-4'>
                            <SoftBox component="form" role="form" ref={formRef} id="myForm">
                                <SoftBox className="newerror">
                                    <SoftBox mb={1} ml={0.5}>
                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                            tag
                                        </SoftTypography>
                                    </SoftBox>
                                    <SoftInput type="text" placeholder="tag" name="tag" value={data.tag} onChange={handleChange} />
                                    <SoftBox className="d-none">
                                        <SoftInput type="text" placeholder="id" name="id" value={data.id} />
                                    </SoftBox>
                                </SoftBox>
                                <SoftBox mt={2} mb={1}>
                                    <SoftButton variant="gradient" type="submit" color="info" fullWidth>
                                        Update tag
                                    </SoftButton>
                                </SoftBox>
                            </SoftBox>
                        </div>
                    </div>
                </div>
                <Footer />
            </DashboardLayout>
        </>
    )
}

export default Update;