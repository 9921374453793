// Soft UI Route Component
import Dashboard from "layouts/dashboard";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import ForgetPasswordEmail from "layouts/authentication/ForgetPasswordEmail";
import OtpVerification from "layouts/authentication/OtpVerification";
import ForgetPasswordMain from "layouts/authentication/ForgetPasswordMain";
import AdminSetting from "layouts/authentication/AdminSetting";
import CoinHistoryView from "layouts/admin/CoinHistoryView";
import Plans_Add from "layouts/admin/plans/Add";
import Plans_ViewAll from "layouts/admin/plans/ViewAll";
import Plans_Update from "layouts/admin/plans/Update";
import Body_Type_Add from "layouts/admin/Body_Type/Add";
import Body_Type_ViewAll from "layouts/admin/Body_Type/ViewAll";
import Body_Type_Update from "layouts/admin/Body_Type/Update";
import I_am_Add from "layouts/admin/I_am/Add";
import I_am_ViewAll from "layouts/admin/I_am/ViewAll";
import I_am_Update from "layouts/admin/I_am/Update";
import I_want_Add from "layouts/admin/I_want/Add";
import I_want_ViewAll from "layouts/admin/I_want/ViewAll";
import I_want_Update from "layouts/admin/I_want/Update";
import Upi_Add from "layouts/admin/Upi/Add";
import Upi_ViewAll from "layouts/admin/Upi/ViewAll";
import Upi_Update from "layouts/admin/Upi/Update";
import Video_Add from "layouts/admin/Video/Add";
import Video_ViewAll from "layouts/admin/Video/ViewAll";
import Video_Update from "layouts/admin/Video/Update";
import App_link_Add from "layouts/admin/App_Link/Add";
import App_link_ViewAll from "layouts/admin/App_Link/ViewAll";
import App_link_Update from "layouts/admin/App_Link/Update";
import App_package_Add from "layouts/admin/App_Package/Add";
import App_package_ViewAll from "layouts/admin/App_Package/ViewAll";
import App_package_Update from "layouts/admin/App_Package/Update";
import Interested_topic_Add from "layouts/admin/Interested_topic/Add";
import Interested_topic_ViewAll from "layouts/admin/Interested_topic/ViewAll";
import Interested_topic_Update from "layouts/admin/Interested_topic/Update";
import Host_Add from "layouts/Host/Add";
import Host_ViewAll from "layouts/Host/ViewAll";
import Host_Update from "layouts/Host/Update";
import ViewImages from "layouts/Host/ViewImages";
import View_Host_Call_History from "layouts/Host/Single_Host_All_Details";
import Privacy_Policy from "layouts/admin/privacy_policy";
import Terms_And_Condition from "layouts/admin/Terms_And_Condition";
import About_Us from "layouts/admin/About_Us";
import User_ViewAll from "layouts/user/ViewAll";
import User_Update from "layouts/user/Update";
import Single_User_All_Details from "layouts/user/Single_User_All_Details";
import User_Transaction_List from "layouts/Transaction/User_Transaction";
import HostweeklyPayout from "layouts/Transaction/HostweeklyPayout";

// Soft UI Dashboard React icons
import Shop from "examples/Icons/Shop";
import Document from "examples/Icons/Document";
import CustomerSupport from "examples/Icons/CustomerSupport";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SortIcon from '@mui/icons-material/Sort';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import SettingsIcon from '@mui/icons-material/Settings';
import SecurityIcon from '@mui/icons-material/Security';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import InfoIcon from '@mui/icons-material/Info';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import AdjustIcon from '@mui/icons-material/Adjust';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import RecentActorsIcon from '@mui/icons-material/RecentActors';

import { Dashboard_path, Dashboard_profile_path, Settings_path, Plan_Add_path, Plan_View_path, Plan_Update_path, Body_Types_Add_path, Body_Types_View_path, Body_Types_Update_path, Interested_topic_Add_path, Interested_topic_View_path, Interested_topic_Update_path, Host_Add_path, Host_View_path, Host_Update_path, Host_ViewImages_path, Admin_OtpVerification_Path, Admin_ForgetPasswordMain_Path, Admin_ForgetPassword_Path, Admin_Login_Path, About_us_Path, Terms_And_Condition_Path, privacy_policy_Path, Coin_History_Path, View_All_path, View_Single_User_path, User_Transactions_list_path, User_Update_path, Upi_Add_path, Upi_View_path, Upi_Update_path, View_Single_Host_Details_path, i_am_Add_path, i_am_View_path, i_am_Update_path, i_want_Add_path, i_want_View_path, i_want_Update_path, Video_View_path, Video_Add_path, Video_Update_path, app_link_Add_path, app_link_View_path, app_link_Update_path, app_package_Add_path, app_package_View_path, app_package_Update_path, Host_weekly_Payout_path } from "Api/Routes";

let AuthToken = localStorage.getItem('authToken');
let routes = [];

if (AuthToken) {
  routes = [
    {
      type: "collapse",
      name: "Dashboard",
      key: "dashboard",
      route: Dashboard_path,
      icon: <Shop size="12px" />,
      component: <Dashboard />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "dashboard",
      route: '/',
      icon: <Shop size="12px" />,
      component: <Dashboard />,
      noCollapse: true,
    },
    { type: "title", title: "Admin", key: "Admin" },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "profile",
      route: Dashboard_profile_path,
      icon: <CustomerSupport size="12px" />,
      component: <Profile />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "Setting",
      route: Settings_path,
      icon: <SettingsIcon size="12px" />,
      component: <AdminSetting />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Video",
      key: "Video",
      icon: <FiberManualRecordIcon size="12px" />,
      noCollapse: false,
      dropdownItems: [
        {
          type: "collapse",
          name: "Add Video",
          key: "Add Video",
          route: Video_Add_path,
          icon: <AdjustIcon size="12px" />,
          component: <Video_Add />,
          noCollapse: true,
        },
        {
          type: "collapse",
          name: "View Video",
          key: "View Video",
          route: Video_View_path,
          icon: <AdjustIcon size="12px" />,
          component: <Video_ViewAll />,
          noCollapse: true,
        },
      ],
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "hide_Nav",
      route: Video_Add_path,
      icon: <AdjustIcon size="12px" />,
      component: <Video_Add />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "hide_Nav",
      route: Video_View_path,
      icon: <AdjustIcon size="12px" />,
      component: <Video_ViewAll />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "hide_Nav",
      route: Video_Update_path,
      icon: <AdjustIcon size="12px" />,
      component: <Video_Update />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "App Link",
      key: "App Link",
      icon: <FiberManualRecordIcon size="12px" />,
      noCollapse: false,
      dropdownItems: [
        {
          type: "collapse",
          name: "Add App Link",
          key: "Add App Link",
          route: app_link_Add_path,
          icon: <AdjustIcon size="12px" />,
          component: <App_link_Add />,
          noCollapse: true,
        },
        {
          type: "collapse",
          name: "View App Link",
          key: "View App Link",
          route: app_link_View_path,
          icon: <AdjustIcon size="12px" />,
          component: <App_link_ViewAll />,
          noCollapse: true,
        },
      ],
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "hide_Nav",
      route: app_link_Add_path,
      icon: <AdjustIcon size="12px" />,
      component: <App_link_Add />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "hide_Nav",
      route: app_link_View_path,
      icon: <AdjustIcon size="12px" />,
      component: <App_link_ViewAll />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "hide_Nav",
      route: app_link_Update_path,
      icon: <AdjustIcon size="12px" />,
      component: <App_link_Update />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "App Package",
      key: "App Package",
      icon: <FiberManualRecordIcon size="12px" />,
      noCollapse: false,
      dropdownItems: [
        {
          type: "collapse",
          name: "Add App Package",
          key: "Add App Package",
          route: app_package_Add_path,
          icon: <AdjustIcon size="12px" />,
          component: <App_package_Add />,
          noCollapse: true,
        },
        {
          type: "collapse",
          name: "View App Package",
          key: "View App Package",
          route: app_package_View_path,
          icon: <AdjustIcon size="12px" />,
          component: <App_package_ViewAll />,
          noCollapse: true,
        },
      ],
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "hide_Nav",
      route: app_package_Add_path,
      icon: <AdjustIcon size="12px" />,
      component: <App_package_Add />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "hide_Nav",
      route: app_package_View_path,
      icon: <AdjustIcon size="12px" />,
      component: <App_package_ViewAll />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "hide_Nav",
      route: app_package_Update_path,
      icon: <AdjustIcon size="12px" />,
      component: <App_package_Update />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Upi",
      key: "Upi",
      icon: <FiberManualRecordIcon size="12px" />,
      noCollapse: false,
      dropdownItems: [
        {
          type: "collapse",
          name: "Add upi",
          key: "Add upi",
          route: Upi_Add_path,
          icon: <AdjustIcon size="12px" />,
          component: <Upi_Add />,
          noCollapse: true,
        },
        {
          type: "collapse",
          name: "View upi",
          key: "View upi",
          route: Upi_View_path,
          icon: <AdjustIcon size="12px" />,
          component: <Upi_ViewAll />,
          noCollapse: true,
        },
      ],
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "hide_Nav",
      route: Upi_Add_path,
      icon: <AdjustIcon size="12px" />,
      component: <Upi_Add />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "hide_Nav",
      route: Upi_View_path,
      icon: <AdjustIcon size="12px" />,
      component: <Upi_ViewAll />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "hide_Nav",
      route: Upi_Update_path,
      icon: <AdjustIcon size="12px" />,
      component: <Upi_Update />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Plans",
      key: "Plans",
      icon: <FiberManualRecordIcon size="12px" />,
      noCollapse: false,
      dropdownItems: [
        {
          type: "collapse",
          name: "Add Plans",
          key: "Add Plans",
          route: Plan_Add_path,
          icon: <AdjustIcon size="12px" />,
          component: <Plans_Add />,
          noCollapse: true,
        },
        {
          type: "collapse",
          name: "View Plans",
          key: "View Plans",
          route: Plan_View_path,
          icon: <AdjustIcon size="12px" />,
          component: <Plans_ViewAll />,
          noCollapse: true,
        },
      ],
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "hide_Nav",
      route: Plan_Add_path,
      icon: <AdjustIcon size="12px" />,
      component: <Plans_Add />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "hide_Nav",
      route: Plan_View_path,
      icon: <AdjustIcon size="12px" />,
      component: <Plans_ViewAll />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "Plans Update",
      route: Plan_Update_path,
      icon: <SortIcon size="12px" />,
      component: <Plans_Update />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Body Type",
      key: "Body Type",
      route: "/",
      icon: <FiberManualRecordIcon size="12px" />,
      noCollapse: false,
      dropdownItems: [
        {
          type: "collapse",
          name: "Add Body Type",
          key: "Add Body Type",
          route: Body_Types_Add_path,
          icon: <AdjustIcon size="12px" />,
          component: <Body_Type_Add />,
          noCollapse: true,
        },
        {
          type: "collapse",
          name: "View Body Type",
          key: "View Body Type",
          route: Body_Types_View_path,
          icon: <AdjustIcon size="12px" />,
          component: <Body_Type_ViewAll />,
          noCollapse: true,
        },
      ],
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "Add Body Type",
      route: Body_Types_Add_path,
      icon: <AdjustIcon size="12px" />,
      component: <Body_Type_Add />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "View Body Type",
      route: Body_Types_View_path,
      icon: <AdjustIcon size="12px" />,
      component: <Body_Type_ViewAll />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "Body Type Update",
      route: Body_Types_Update_path,
      icon: <SortIcon size="12px" />,
      component: <Body_Type_Update />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "I Am Tag",
      key: "I Am Tag",
      route: "/",
      icon: <FiberManualRecordIcon size="12px" />,
      noCollapse: false,
      dropdownItems: [
        {
          type: "collapse",
          name: "Add I Am Tag",
          key: "Add I Am Tag",
          route: i_am_Add_path,
          icon: <AdjustIcon size="12px" />,
          component: <I_am_Add />,
          noCollapse: true,
        },
        {
          type: "collapse",
          name: "View I Am Tag",
          key: "View I Am Tag",
          route: i_am_View_path,
          icon: <AdjustIcon size="12px" />,
          component: <I_am_ViewAll />,
          noCollapse: true,
        },
      ],
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "Add I Am Tag",
      route: i_am_Add_path,
      icon: <AdjustIcon size="12px" />,
      component: <I_am_Add />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "View I Am Tag",
      route: i_am_View_path,
      icon: <AdjustIcon size="12px" />,
      component: <I_am_ViewAll />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "Update I Am Tag",
      route: i_am_Update_path,
      icon: <SortIcon size="12px" />,
      component: <I_am_Update />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "I Want Tag",
      key: "I Want Tag",
      route: "/",
      icon: <FiberManualRecordIcon size="12px" />,
      noCollapse: false,
      dropdownItems: [
        {
          type: "collapse",
          name: "Add I Want",
          key: "Add I Want",
          route: i_want_Add_path,
          icon: <AdjustIcon size="12px" />,
          component: <I_want_Add />,
          noCollapse: true,
        },
        {
          type: "collapse",
          name: "View I Want",
          key: "View I Want",
          route: i_want_View_path,
          icon: <AdjustIcon size="12px" />,
          component: <I_want_ViewAll />,
          noCollapse: true,
        },
      ],
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "Add I Want",
      route: i_want_Add_path,
      icon: <AdjustIcon size="12px" />,
      component: <I_want_Add />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "View I Want",
      route: i_want_View_path,
      icon: <AdjustIcon size="12px" />,
      component: <I_want_ViewAll />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "I Want Update",
      route: i_want_Update_path,
      icon: <SortIcon size="12px" />,
      component: <I_want_Update />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Interested Topic",
      key: "Interested Topic",
      route: "/",
      icon: <FiberManualRecordIcon size="12px" />,
      noCollapse: false,
      dropdownItems: [
        {
          type: "collapse",
          name: "Add Interested Topic",
          key: "Add Interested Topic",
          route: Interested_topic_Add_path,
          icon: <AdjustIcon size="12px" />,
          component: <Interested_topic_Add />,
          noCollapse: true,
        },
        {
          type: "collapse",
          name: "View Interested Topic",
          key: "View Interested Topic",
          route: Interested_topic_View_path,
          icon: <AdjustIcon size="12px" />,
          component: <Interested_topic_ViewAll />,
          noCollapse: true,
        },
      ],
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "Add Interested Topic",
      route: Interested_topic_Add_path,
      icon: <AddCircleOutlineIcon size="12px" />,
      component: <Interested_topic_Add />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "View Interested Topic",
      route: Interested_topic_View_path,
      icon: <SortIcon size="12px" />,
      component: <Interested_topic_ViewAll />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "Interested Topic Update",
      route: Interested_topic_Update_path,
      icon: <SortIcon size="12px" />,
      component: <Interested_topic_Update />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "User",
      key: "User",
      route: "/",
      icon: <FiberManualRecordIcon size="12px" />,
      noCollapse: false,
      dropdownItems: [
        {
          type: "collapse",
          name: "View User",
          key: "View User",
          route: View_All_path,
          icon: <AdjustIcon size="12px" />,
          component: <User_ViewAll />,
          noCollapse: true,
        },
      ],
    },
    {
      type: "collapse",
      name: "Host",
      key: "Host",
      route: "/",
      icon: <FiberManualRecordIcon size="12px" />,
      noCollapse: false,
      dropdownItems: [
        {
          type: "collapse",
          name: "Add Host",
          key: "Add Host",
          route: Host_Add_path,
          icon: <AdjustIcon size="12px" />,
          component: <Host_Add />,
          noCollapse: true,
        },
        {
          type: "collapse",
          name: "View Host",
          key: "View Host",
          route: Host_View_path,
          icon: <AdjustIcon size="12px" />,
          component: <Host_ViewAll />,
          noCollapse: true,
        },
      ],
    },
    {
      type: "collapse",
      name: "Transaction",
      key: "Transaction",
      route: "/",
      icon: <FiberManualRecordIcon size="12px" />,
      noCollapse: false,
      dropdownItems: [
        {
          type: "collapse",
          name: "User Transaction List",
          key: "User Transaction List",
          route: User_Transactions_list_path,
          icon: <AdjustIcon size="12px" />,
          component: <User_Transaction_List />,
          noCollapse: true,
        },
        {
          type: "collapse",
          name: "Coin History View",
          key: "Coin History View",
          route: Coin_History_Path,
          icon: <AdjustIcon size="12px" />,
          component: <CoinHistoryView />,
          noCollapse: true,
        },
        {
          type: "collapse",
          name: "Host Weekly Payout",
          key: "Host Weekly Payout",
          route: Host_weekly_Payout_path,
          icon: <AdjustIcon size="12px" />,
          component: <HostweeklyPayout />,
          noCollapse: true,
        },
      ],
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "Add Host",
      route: Host_Add_path,
      icon: <AddCircleOutlineIcon size="12px" />,
      component: <Host_Add />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "Host Weekly Payout",
      route: Host_weekly_Payout_path,
      icon: <AdjustIcon size="12px" />,
      component: <HostweeklyPayout />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "View Host",
      route: Host_View_path,
      icon: <SortIcon size="12px" />,
      component: <Host_ViewAll />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "Host Update",
      route: Host_Update_path,
      icon: <SortIcon size="12px" />,
      component: <Host_Update />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "User Update",
      route: User_Update_path,
      icon: <SortIcon size="12px" />,
      component: <User_Update />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "View Host Call History",
      route: View_Single_Host_Details_path,
      icon: <SortIcon size="12px" />,
      component: <View_Host_Call_History />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "View Images",
      route: Host_ViewImages_path,
      icon: <SortIcon size="12px" />,
      component: <ViewImages />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Privacy Policy",
      key: "Privacy Policy",
      route: privacy_policy_Path,
      icon: <SecurityIcon size="12px" />,
      component: <Privacy_Policy />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Terms And Condition",
      key: "Terms And Condition",
      route: Terms_And_Condition_Path,
      icon: <PlaylistAddCheckIcon size="12px" />,
      component: <Terms_And_Condition />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "About Us",
      key: "About Us",
      route: About_us_Path,
      icon: <InfoIcon size="12px" />,
      component: <About_Us />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "Coin History View",
      route: Coin_History_Path,
      icon: <CurrencyExchangeIcon size="12px" />,
      component: <CoinHistoryView />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "View User Details",
      route: View_Single_User_path,
      icon: <RecentActorsIcon size="12px" />,
      component: <Single_User_All_Details />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "User Transactions list path",
      route: User_Transactions_list_path,
      icon: <RecentActorsIcon size="12px" />,
      component: <User_Transaction_List />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "View User",
      route: View_All_path,
      icon: <RecentActorsIcon size="12px" />,
      component: <User_ViewAll />,
      noCollapse: true,
    },
  ];
}
else {
  routes = [
    {
      type: "collapse",
      name: "Dashboard",
      key: "dashboard",
      route: Dashboard_path,
      icon: <Shop size="12px" />,
      component: <Dashboard />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "sign-in",
      route: Admin_Login_Path,
      icon: <Document size="12px" />,
      component: <SignIn />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "ForgetPasswordEmail",
      route: Admin_ForgetPassword_Path,
      icon: <Shop size="12px" />,
      component: <ForgetPasswordEmail />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "ForgetPasswordMain",
      route: Admin_ForgetPasswordMain_Path,
      icon: <Shop size="12px" />,
      component: <ForgetPasswordMain />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "hide_Nav",
      key: "OtpVerification",
      route: Admin_OtpVerification_Path,
      icon: <Shop size="12px" />,
      component: <OtpVerification />,
      noCollapse: true,
    },
  ];
}



export default routes;
