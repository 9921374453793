import React, { useEffect, useRef, useState } from 'react'
import Footer from 'examples/Footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Alert from "assets/Custom_alert/Custom_Alert";
import $ from 'jquery';
import 'jquery-validation';
import axios from 'axios';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import SoftButton from 'components/SoftButton';
import SoftBox from 'components/SoftBox';
import { Update_Terms_And_Condition_Api } from 'Api/Api';
import { Get_Terms_And_Condition_Api } from 'Api/Api';

const App = () => {
    const [msg, setmsg] = useState({ msg: '', status: '' });
    const [data, setdata] = useState('');
    const GetData = () => {
        var config = {
            method: 'post',
            url: `${Get_Terms_And_Condition_Api}`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
        };
        axios(config)
            .then((response) => {
                setdata(response.data.data.content)
            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        GetData();
    }, []);
    const handleChange = (event, editor) => {
        const data = editor.getData();
        setdata(data)
    };
    const Update_Terms_And_Condition = () => {
        var config = {
            method: 'post',
            url: `${Update_Terms_And_Condition_Api}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
            data: { content: data }
        };
        axios(config)
            .then((response) => {
                if (response.data.success == true) {
                    setmsg({ msg: response.data.message, status: 'success' });
                } else {
                    setmsg({ msg: response.data.message, status: 'error' });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    return (
        <>
            {msg.msg && (
                <>
                    <div className="alert-container">
                        <Alert msg={msg.msg} status={msg.status} />
                    </div>
                    <div className='d-none'>
                        {setTimeout(() => {
                            setmsg({ msg: '', status: '' })
                        }, 3000)}
                    </div>
                </>
            )
            }
            <DashboardLayout>
                <DashboardNavbar />
                <div>
                    <h2>TERMS AND CONDITIONS</h2>
                    <CKEditor editor={ClassicEditor} onChange={handleChange} data={data} />
                    <div className='row text-end'>
                        <SoftBox mt={2} mb={1}>
                            <SoftButton variant="gradient" type="submit" color="info" onClick={() => Update_Terms_And_Condition()}>
                                Save Changes
                            </SoftButton>
                        </SoftBox>
                    </div>
                </div>
                <Footer />
            </DashboardLayout>
        </>
    )
}

export default App;