import React, { useEffect, useRef, useState } from 'react'
import Footer from 'examples/Footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Alert from "assets/Custom_alert/Custom_Alert";
import $ from 'jquery';
import 'jquery-validation';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { Get_All_Host_Data_Api } from 'Api/Api';
import { Delete_Host_Data_Api } from 'Api/Api';
import { Host_ViewImages_path } from 'Api/Routes';
import { Host_Update_path } from 'Api/Routes';
import { View_Single_Host_Details_path } from 'Api/Routes';

const ViewAll = () => {
    const [msg, setmsg] = useState({ msg: '', status: '' });
    const [data, setdata] = useState([]);
    const formRef = useRef(null);
    const GetData = () => {
        var config = {
            method: 'post',
            url: `${Get_All_Host_Data_Api}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
        };
        axios(config)
            .then((response) => {
                setdata(response.data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        GetData();
    }, []);
    const column = (() => [
        {
            name: 'Username',
            selector: row => row.username,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Coins',
            selector: row => row.coins,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status == 0 ? "Offline" : row.status == 1 ? 'Online' : 'Busy',
            sortable: true,
        },
        {
            name: 'Pending Coins',
            selector: row => row.pending_coins,
            sortable: true,
        },
        {
            name: 'Created At',
            selector: row => row.created_at,
            sortable: true,
        },
        {
            name: 'Updated At',
            selector: row => row.updated_at,
            sortable: true,
        },
        {
            name: 'Action',
            sortable: true,
            width: '38%',
            cell: (row) => <><td data-order="400">
                <Link
                    className="btn btn_secondary_css"
                    to={`${Host_ViewImages_path}?id=${row.id}`}
                >
                    Images
                </Link>
                <Link
                    className="btn btn_secondary_css ms-2"
                    to={`${View_Single_Host_Details_path}?id=${row.id}`}
                >
                    Details
                </Link>
                <Link
                    className="btn btn_css ms-2"
                    to={`${Host_Update_path}?id=${row.id}`}
                >
                    Update
                </Link>
                <a className="btn btn_danger_css px-4 ms-2" onClick={(e) => Delete(e, row.id)}>Delete</a>
            </td>
            </>
        },
    ]);
    const Delete = (e, id) => {
        e.preventDefault();
        var config = {
            method: 'post',
            url: `${Delete_Host_Data_Api}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
            data: { id: id },
        }
        axios(config)
            .then((response) => {
                if (response.data.success === true) {
                    setmsg({ msg: response.data.message, status: 'success' });
                    GetData();
                } else {
                    setmsg({ msg: response.data.message, status: 'error' });
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }
    const handleChange = (e) => {
        e.preventDefault()
    }
    return (
        <>
            {msg.msg && (
                <>
                    <div className="alert-container">
                        <Alert msg={msg.msg} status={msg.status} />
                    </div>
                    <div className='d-none'>
                        {setTimeout(() => {
                            setmsg({ msg: '', status: '' })
                        }, 3000)}
                    </div>
                </>
            )
            }
            <DashboardLayout>
                <DashboardNavbar />
                <div className='container min-vh-For_Footer' style={{ paddingTop: '2%' }}>
                    <div className='row justify-content-center'>
                        <DataTable
                            data={data}
                            columns={column()}
                            onRowSelected={handleChange}
                            pagination
                            dense
                        />
                    </div>
                </div>
                <Footer />
            </DashboardLayout>
        </>
    )
}

export default ViewAll;