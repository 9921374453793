
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";


import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";

import Projects from "layouts/dashboard/components/Projects";
import OrderOverview from "layouts/dashboard/components/OrderOverview";
import axios from "axios";
import { useEffect, useState } from "react";
import { Dashboard_RechargeAmount_Api, Dashboard_UserTotal_Api, Dashboard_HostTotal_Api, Get_Single_Year_RechargeData_Api, Get_One_Month_RechargeData_Api, Host_And_User_Count_Of_TheYear_Api } from "Api/Api";
import { GetDayWiseRechargeChart_Api } from "Api/Api";

function Dashboard() {
  const [AllRechargeAmount, setAllRechargeAmount] = useState(0);
  const [TodayRechargeAmount, setTodayRechargeAmount] = useState(0);
  const [AllUser, setAllUser] = useState(0);
  const [TodayNewUser, setTodayNewUser] = useState(0);
  const [AllHost, setAllHost] = useState(0);
  const [TodayNewHost, setTodayNewHost] = useState(0);
  const [SingleYearRechargeReport, setSingleYearRechargeReport] = useState({
    chart: {
      labels: [],
      datasets: { label: "Sales", data: [] },
    }
  });
  const [SingleMonthRechargeReport, setSingleMonthRechargeReport] = useState({
    chart: {
      labels: [],
      datasets: { label: "Sales", data: [] },
    }
  });
  const [HostAndUserCountOfTheYear, setHostAndUserCountOfTheYear] = useState({
    labels: [],
    datasets: [],
  });

  const [DayWiseRechargeChart, setDayWiseRechargeChart] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {

    // Get AllRecharge Amount Api
    var config = {
      method: 'post',
      url: `${Dashboard_RechargeAmount_Api}`,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    };
    axios(config)
      .then((response) => {
        setTodayRechargeAmount(response.data.data.Today_Recharge_Amount);
        setAllRechargeAmount(response.data.data.All_Time_Recharge_Amount);
      })
      .catch((error) => {
        console.log(error);
      });

    // Get All User Api
    var config = {
      method: 'post',
      url: `${Dashboard_UserTotal_Api}`,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    };
    axios(config)
      .then((response) => {
        setAllUser(response.data.data.AllUser);
        setTodayNewUser(response.data.data.TodayNewUser);
      })
      .catch((error) => {
        console.log(error);
      });

    // Get All Host Api
    var config = {
      method: 'post',
      url: `${Dashboard_HostTotal_Api}`,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    };
    axios(config)
      .then((response) => {
        setTodayNewHost(response.data.data.TodayNewHost);
        setAllHost(response.data.data.AllHost);
      })
      .catch((error) => {
        console.log(error);
      });

    // Get Single Month RechargeData Api
    var config = {
      method: 'post',
      url: `${Get_Single_Year_RechargeData_Api}`,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    };
    axios(config)
      .then((response) => {
        let result = response.data.data.data;
        let labels = [];
        let Values = [];
        for (const key in result) {
          labels.push(result[key].month + '-' + result[key].year);
          Values.push(result[key].total_amount);
        }
        setSingleYearRechargeReport({
          chart: {
            labels: labels,
            datasets: { label: "Recharge", data: Values },
          }
        })
      })
      .catch((error) => {
        console.log(error);
      });

    // last 4Week RechargeData Api
    var config = {
      method: 'post',
      url: `${Get_One_Month_RechargeData_Api}`,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    };
    axios(config)
      .then((response) => {
        let result = response.data.data.data;
        let labels = [];
        let Values = [];
        for (const key in result) {
          labels.push(result[key].week + ' - Week');
          Values.push(result[key].total_amount);
        }
        setSingleMonthRechargeReport({
          chart: {
            labels: labels,
            datasets: { label: "Recharge", data: Values },
          }
        })
      })
      .catch((error) => {
        console.log(error);
      });

    // Host And User Count Of TheYear Api
    var config = {
      method: 'post',
      url: `${Host_And_User_Count_Of_TheYear_Api}`,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    };
    axios(config)
      .then((response) => {
        let result = response.data.data.data;
        let labels = [];
        let UserValues = [];
        let HostValues = [];
        for (const key in result) {
          labels.push(result[key].month + '-' + result[key].year);
          UserValues.push(result[key].user_count);
          HostValues.push(result[key].host_count);
        }
        setHostAndUserCountOfTheYear({
          labels: labels,
          datasets: [
            {
              label: "New User",
              color: "info",
              data: UserValues,
            },
            {
              label: "New Host",
              color: "dark",
              data: HostValues,
            },
          ],
        })
      })
      .catch((error) => {
        console.log(error);
      });

    // Get Day Wise Recharge Chart Api
    var config = {
      method: 'post',
      url: `${GetDayWiseRechargeChart_Api}`,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      }
    };
    axios(config)
      .then((response) => {
        let result = response.data.data.data;
        let labels = [];
        let RechargeValue = [];
        for (const key in result) {
          labels.push(result[key].date);
          RechargeValue.push(result[key].total_amount);
        }
        setDayWiseRechargeChart({
          labels: labels,
          datasets: [
            {
              label: "New User",
              color: "info",
              data: RechargeValue,
            },
          ],
        })
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  if (!localStorage.getItem('authToken')) {
    // window.location.href = 'http://localhost:3000/login'
    window.location.href = 'https://admin.omegames.in/login'
  }
  const { size } = typography;
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Total recharge amount" }}
                count={AllRechargeAmount + " ₹" || 0 + " ₹"}
                icon={{ color: "info", component: "paid" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Today's recharge amount" }}
                count={TodayRechargeAmount + " ₹" || 0 + " ₹"}
                icon={{ color: "info", component: "paid" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Total users" }}
                count={AllUser || 0}
                icon={{ color: "info", component: "public" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Today's new users" }}
                count={TodayNewUser || 0}
                icon={{ color: "info", component: "public" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Total host" }}
                count={AllHost || 0}
                icon={{ color: "info", component: "emoji_events" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Today's new host" }}
                count={TodayNewHost || 0}
                icon={{ color: "info", component: "emoji_events" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Total Host Paid" }}
                count={TodayNewUser || 0}
                icon={{ color: "info", component: "public" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Today's Host Paid" }}
                count={TodayNewHost || 0}
                icon={{ color: "info", component: "emoji_events" }}
              />
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <ReportsBarChart
                title="Monthly Recharge Chart"
                chart={SingleYearRechargeReport.chart}
                label={SingleYearRechargeReport.chart.labels}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <ReportsBarChart
                title="Last 4Week Recharge Chart"
                chart={SingleMonthRechargeReport.chart}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <GradientLineChart
                title="Last 12 month Created user and host chart"
                height="20.25rem"
                chart={HostAndUserCountOfTheYear}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <GradientLineChart
                title="This Month Recharge chart"
                height="20.25rem"
                chart={DayWiseRechargeChart}
              />
            </Grid>
          </Grid>
        </SoftBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <Projects />
          </Grid>
          {/* <Grid item xs={12} md={6} lg={4}>
            <OrderOverview />
          </Grid> */}
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
