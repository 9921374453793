import React, { useEffect, useRef, useState } from 'react'
import Footer from 'examples/Footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Alert from "assets/Custom_alert/Custom_Alert";
import 'jquery-validation';
import axios from 'axios';
import SoftButton from 'components/SoftButton';
import SoftBox from 'components/SoftBox';
import { Get_All_Host_Images_Data_Api, Insert_Multiple_Host_Image_Api, Delete_Host_Images_Data_Api } from 'Api/Api';
import CloseIcon from '@mui/icons-material/Close';

const ViewAll = () => {
    const [msg, setmsg] = useState({ msg: '', status: '' });
    const [data, setdata] = useState([]);
    const id = new URLSearchParams(window.location.search).get('id');
    const [Profile_Images, setProfile_Images] = useState();
    const formRef = useRef(null);
    const GetData = () => {
        var config = {
            method: 'post',
            url: `${Get_All_Host_Images_Data_Api}/${id}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
        };
        axios(config)
            .then((response) => {
                setdata(response.data.data.image_data)
            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        GetData();
    }, []);
    const Delete = (e, id) => {
        e.preventDefault();
        var config = {
            method: 'post',
            url: `${Delete_Host_Images_Data_Api}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
            data: { id: id },
        }
        axios(config)
            .then((response) => {
                if (response.data.success === true) {
                    setmsg({ msg: response.data.message, status: 'success' });
                    GetData();
                } else {
                    setmsg({ msg: response.data.message, status: 'error' });
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }
    const Images_Upload = () => {
        if (Profile_Images === "" || Profile_Images === null || Profile_Images === undefined) {
            setmsg({ msg: "Please Select Image", status: 'error' });
        }
        else {
            let n_form = new FormData();
            n_form.append('id', id)
            for (const key in Profile_Images) {
                n_form.append('Profile_Images', Profile_Images[key]);
            }
            var config = {
                method: 'post',
                url: `${Insert_Multiple_Host_Image_Api}`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                data: n_form
            };
            axios(config)
                .then((response) => {
                    if (response.data.success === true) {
                        setmsg({ msg: response.data.message, status: 'success' });
                        GetData();
                    } else {
                        setmsg({ msg: response.data.message, status: 'error' });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }
    const ImageHandler = (e) => {
        setProfile_Images(e.target.files);
    }
    return (
        <>
            {msg.msg && (
                <>
                    <div className="alert-container">
                        <Alert msg={msg.msg} status={msg.status} />
                    </div>
                    <div className='d-none'>
                        {setTimeout(() => {
                            setmsg({ msg: '', status: '' })
                        }, 3000)}
                    </div>
                </>
            )
            }
            <DashboardLayout>
                <DashboardNavbar />
                <div className='container min-vh-For_Footer' style={{ paddingTop: '2%' }}>
                    <div className='row justify-content-center'>
                        <div className='col-3'>
                            <SoftBox mt={2} mb={1} className="text-start">
                                <SoftButton variant="gradient" className="me-3 position-relative" color="secondary">
                                    <input type="file" name="Profile_Images" className="custom_Upload_Images" multiple onChange={ImageHandler} />
                                    Choose Images
                                </SoftButton>
                                <SoftButton variant="gradient" type="submit" color="info" onClick={Images_Upload}>
                                    Save
                                </SoftButton>
                            </SoftBox>
                        </div>
                        <div className='col-9 d-flex flex-wrap'>
                            {data.map((item, index) => {
                                return (
                                    <div className='col-3 custom-Images-wrapper' key={index}>
                                        <div className='position-relative'>
                                            <img src={item.image} />
                                            <a className='Image_Remove_Custom' onClick={(e) => Delete(e, item.id)}><CloseIcon /></a>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>

                    </div>
                </div>
                <Footer />
            </DashboardLayout>
        </>
    )
}

export default ViewAll;