/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react


Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef, useState } from "react";


import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import $ from 'jquery';
import 'jquery-validation';

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
// Images
import curved9 from "assets/images/curved-images/curved-6.jpg";
import { Admin_Login_Api } from "Api/Api";
import { Dashboard_url } from "Api/Routes";
import axios from "axios";
import Alert from "assets/Custom_alert/Custom_Alert";
import { Admin_ForgetPassword_Url } from "Api/Routes";

const SignIn = () => {
  const [msg, setmsg] = useState({ msg: '', status: '' });
  const formRef = useRef(null);
  useEffect(() => {
    $(formRef.current).validate({
      rules: {
        email: {
          required: true,
          email: true,
        },
        password: "required"
      },
      messages: {
        email: {
          required: 'Please enter your email address',
          email: 'Please enter a valid email address',
        },
        password: "Please Enter Password"
      },
      errorPlacement: function (error, element) {
        error.insertAfter($(element).closest(".newerror"));
      },
      submitHandler: (form) => {
        let n_form = $(form).serialize();
        var config = {
          method: 'post',
          url: `${Admin_Login_Api}`,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          data: n_form,
        };
        axios(config)
          .then((response) => {
            let result = response.data;
            if (result.success == true) {
              setmsg({ msg: result.message, status: 'success' });
              setTimeout(() => {
                localStorage.setItem('authToken', result.data.authToken);
                localStorage.setItem('data', JSON.stringify(result.data.data));
                localStorage.setItem('email', result.data.data.email);
                window.location.href = `${Dashboard_url}`;
              }, 3000);
            } else {
              setmsg({ msg: result.message, status: 'error' });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
    });
  }, [])
  return (
    <>
      {msg.msg && (
        <>
          <div className="alert-container">
            <Alert msg={msg.msg} status={msg.status} />
          </div>
          <div className='d-none'>
            {setTimeout(() => {
              setmsg({ msg: '', status: '' })
            }, 3000)}
          </div>
        </>
      )}
      <CoverLayout
        title="Welcome back"
        description="Enter your email and password to sign in"
        image={curved9}
      >
        <SoftBox component="form" role="form" ref={formRef} id="myForm">
          <SoftBox className="newerror">
            <SoftBox mb={1} ml={0.5}>
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Email
              </SoftTypography>
            </SoftBox>
            <SoftInput type="email" placeholder="Email" name="email" />
          </SoftBox>
          <SoftBox className="newerror">
            <SoftBox mb={1} ml={0.5}>
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Password
              </SoftTypography>
            </SoftBox>
            <SoftInput type="password" placeholder="password" name="password" />
          </SoftBox>
          <SoftBox mt={2} mb={1}>
            <SoftButton variant="gradient" type="submit" color="info" fullWidth>
              sign in
            </SoftButton>
          </SoftBox>
        </SoftBox>
        <div className="col-12 text-end">
          <a style={{ color: "blue", fontSize: "15px" }} href={Admin_ForgetPassword_Url}>Forget Password</a>
        </div>
      </CoverLayout>
    </>
  );
}

export default SignIn;
