import React, { useEffect, useRef, useState } from 'react'
import Footer from 'examples/Footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Alert from "assets/Custom_alert/Custom_Alert";
import 'jquery-validation';
import axios from 'axios';
import SoftButton from 'components/SoftButton';
import SoftBox from 'components/SoftBox';
import DatePicker from "react-datepicker";
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, usePagination } from 'react-table'
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { GetHostFullDetails_Api } from 'Api/Api';
import { FetchDataByDateRange_Api } from 'Api/Api';
import { UserFetchDataByDateRange_Api } from 'Api/Api';
import { HostCallHistoryfetchDataByDateRange_Api } from 'Api/Api';
import { SingleHostCallHistoryfetchDataByDateRange_Api } from 'Api/Api';

const App = () => {
    const [msg, setmsg] = useState({ msg: '', status: '' });
    const [Image_data, setImage_data] = useState([]);
    const [Details_data, setDetails_data] = useState({
        i_am: [],
        body_types: [],
        i_want: [],
        i_want_you: [],
        intrested_topics: [],
    });
    const [data, setdata] = useState([]);
    const [FromDate, setFromDate] = useState(null);
    const [ToDate, setToDate] = useState(null);
    const Table_Data = [];
    const id = new URLSearchParams(window.location.search).get('id');
    const GetData = () => {
        var config = {
            method: 'post',
            url: `${GetHostFullDetails_Api}/${id}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
        };
        axios(config)
            .then((response) => {
                let result = response.data.data;
                setImage_data(result.image_data);
                setDetails_data(result.Information_Data);
                setdata(result.Call_List);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        GetData();
    }, []);

    const Date_Filter_Function = (e) => {
        var config = {
            method: 'post',
            url: `${SingleHostCallHistoryfetchDataByDateRange_Api}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
            data: {
                startDate: FromDate,
                endDate: ToDate,
                id: id
            }
        };
        axios(config)
            .then((response) => {
                setdata(response.data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        GetData();
    }, []);

    function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter }, }) {
        const count = preFilteredRows.length;
        return (
            <input
                className="form-control"
                value={filterValue || ''}
                onChange={e => {
                    setFilter(e.target.value || undefined)
                }}
                placeholder={`Search ${count} records...`}
            />
        )
    }
    DefaultColumnFilter.propTypes = {
        column: PropTypes.object.isRequired,
        columns: PropTypes.array.isRequired,
        data: PropTypes.array.isRequired,
    };
    const FromDateComponent = () => {
        const [selectedDate, setSelectedDate] = useState(null);
        const handleDateChange = (date) => {
            setFromDate(date);
        };
        return (
            <div>
                <DatePicker
                    selected={FromDate}
                    onChange={handleDateChange}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Select Date"
                    className="form-control"
                />
            </div>
        );
    };

    const ToDateComponent = () => {
        const [selectedDate, setSelectedDate] = useState(null);
        const handleDateChange = (date) => {
            setToDate(date);
        };
        return (
            <div>
                <DatePicker
                    selected={ToDate}
                    onChange={handleDateChange}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Select Date"
                    className="form-control"
                />
            </div>
        );
    };

    function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
        const count = preGlobalFilteredRows.length
        const [value, setValue] = React.useState(globalFilter)
        const onChange = useAsyncDebounce(value => {
            setGlobalFilter(value || undefined)
        }, 200)

        return (
            <div className="d-flex mb-3 align-items-end">
                <span className="col-6 px-2">
                    Search:{' '}
                    <input
                        className="form-control"
                        value={value || ""}
                        onChange={e => {
                            setValue(e.target.value);
                            onChange(e.target.value);
                        }}
                        placeholder={`${count} records...`}
                    />
                </span>
                <div className="d-flex px-2 align-items-center">
                    <h6 className="me-2">From :</h6> <FromDateComponent /> <h6 className="mx-2">To :</h6> <ToDateComponent />
                    <SoftButton variant="gradient" type="button" onClick={Date_Filter_Function} color="info" >
                        Filter
                    </SoftButton>
                </div>
            </div>
        )
    }

    GlobalFilter.propTypes = {
        preGlobalFilteredRows: PropTypes.array.isRequired,
        globalFilter: PropTypes.string.isRequired,
        setGlobalFilter: PropTypes.func.isRequired,
    };

    const Table = ({ columns, data }) => {
        const defaultColumn = React.useMemo(
            () => ({
                Filter: DefaultColumnFilter,
            }),
            []
        )
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            prepareRow,
            page,
            state,
            canPreviousPage,
            canNextPage,
            pageOptions,
            pageCount,
            gotoPage,
            nextPage,
            previousPage,
            setPageSize,
            preGlobalFilteredRows,
            setGlobalFilter,
            state: { pageIndex, pageSize },
        } = useTable(
            {
                columns,
                data,
                defaultColumn,
                initialState: { pageIndex: 0, pageSize: 5 },
            },
            useGlobalFilter,
            useFilters,
            usePagination,
        )
        return (
            <div>
                <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />
                <table className="table" {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup, key) => (
                            <tr {...headerGroup.getHeaderGroupProps()} key={''}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps()} key={''} className="Table_Title">
                                        {column.render('Header')}
                                        <div>{column.canFilter ? column.render('Filter') : null}</div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()} key={''} className="Table_data_tr">
                                    {row.cells.map(cell => {
                                        return <td {...cell.getCellProps()} key={''}>{cell.render('Cell')}</td>
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <ul className="pagination float-end ">
                    <li className="page-item cursor-pointor" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        <a className="page-link text-dark">First</a>
                    </li>
                    <li className="page-item cursor-pointor" onClick={() => previousPage()} disabled={!canPreviousPage}>
                        <a className="page-link text-dark">{'<'}</a>
                    </li>
                    <li className="page-item cursor-pointor" onClick={() => nextPage()} disabled={!canNextPage}>
                        <a className="page-link text-dark">{'>'}</a>
                    </li>
                    <li className="page-item cursor-pointor" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        <a className="page-link text-dark">Last</a>
                    </li>
                    <li>
                        <a className="page-link text-dark cursor-pointor">
                            Page{' '}
                            <strong>
                                {pageIndex + 1} of {pageOptions.length}
                            </strong>{' '}
                        </a>
                    </li>
                    <select
                        className="form-control cursor-pointor"
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value))
                        }}
                        style={{ width: '120px', height: '38px' }}
                    >
                        {[5, 10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                </ul>
            </div>
        )
    }

    Table.propTypes = {
        columns: PropTypes.object.isRequired,
        data: PropTypes.array.isRequired,
    };


    data.map((item, index) => {
        Table_Data.push({
            "user_name": item.user_name,
            // "User_Coin": item.User_Coin,
            "Host_Coin": item.Host_Coin,
            "duration": item.duration,
            "call_status": item.call_status,
            "created_at": new Date(item.created_at).toLocaleString(),
            "updated_at": new Date(item.updated_at).toLocaleString(),
        })
    });

    const columns = React.useMemo(
        () => [
            {
                Header: 'User Name',
                accessor: 'user_name',
            },
            // {
            //     Header: 'User Coin',
            //     accessor: 'User_Coin',
            // },
            {
                Header: 'Coin',
                accessor: 'Host_Coin',
            },
            {
                Header: 'Call Duration',
                accessor: 'duration',
            },
            {
                Header: 'Call Status',
                accessor: 'call_status',
            },
            {
                Header: 'Created At',
                accessor: 'created_at',
            },
            {
                Header: 'Updated At',
                accessor: 'updated_at',
            },
        ],
        []
    );


    return (
        <>
            {msg.msg && (
                <>
                    <div className="alert-container">
                        <Alert msg={msg.msg} status={msg.status} />
                    </div>
                    <div className='d-none'>
                        {setTimeout(() => {
                            setmsg({ msg: '', status: '' })
                        }, 3000)}
                    </div>
                </>
            )
            }
            <DashboardLayout>
                <DashboardNavbar />
                <div className='container min-vh-For_Footer' style={{ paddingTop: '2%' }}>
                    <div className='row justify-content-center'>
                        <div className='col-12 d-flex'>
                            <div className='col-2'>
                                <h4>Profile Image</h4>
                                <img className='Profile_Image' src={Details_data.image} />
                            </div>
                            <div className='col-10'>
                                <div className='col-9 d-flex flex-wrap'>
                                    <h4 className='col-12'>Posts</h4>
                                    <div className='col-12 d-flex flex-wrap' style={{ maxHeight: "400px", overflow: 'scroll' }}>
                                        {Image_data.map((item, index) => {
                                            return (
                                                <div className='col-3 custom-Images-wrapper' key={index}>
                                                    <div className='position-relative'>
                                                        <img src={item.image} />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 d-flex flex-wrap py-3'>
                            <hr className='col-12' />
                            <h2 className='col-12 mb-0'>Details</h2>
                            <hr className='col-12' />
                            <div className='col-6'>
                                <h6>UserName : <b>{Details_data.username}</b></h6>
                                <h6>Email : <b>{Details_data.email}</b></h6>
                                <h6>Country : <b>{Details_data.country}</b></h6>
                                <h6>Age : <b>{Details_data.age}</b></h6>
                                <h6>Attrective Part : <b>{Details_data.attrective_part}</b></h6>
                                <h6>Education : <b>{Details_data.education}</b></h6>
                                <h6>Coins : <b>{Details_data.coins}</b></h6>
                            </div>
                            <div className='col-6'>
                                <h6>I Am : <b>{Details_data.i_am.map((item) => {
                                    return item + ' || '
                                })}</b></h6>
                                <h6>Body Types : <b>{Details_data.body_types.map((item) => {
                                    return item + ' || '
                                })}</b></h6>
                                <h6>Job : <b>{Details_data.job}</b></h6>
                                <h6>I Want : <b>{Details_data.i_want.map((item) => {
                                    return item + ' || '
                                })}</b></h6>
                                <h6>I Want You : <b>{Details_data.i_want_you.map((item) => {
                                    return item + ' || '
                                })}</b></h6>
                                <h6>Interested Topic : <b>{Details_data.intrested_topics.map((item) => {
                                    return item + ' || '
                                })}</b></h6>
                                <h6>Created At : <b>{Details_data.created_at}</b></h6>
                            </div>
                        </div>

                        <div className='col-12'>
                            <hr className='col-12' />
                            <h2 className='col-12 mb-0'>Call List</h2>
                            <hr className='col-12' />


                            <Table columns={columns} data={Table_Data} />

                        </div>

                    </div>
                </div>
                <Footer />
            </DashboardLayout>
        </>
    )
}

export default App;