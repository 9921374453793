const port = 9000;
// const url = `http://localhost:${port}/api`;
const url = `https://api.omegames.in/api`;
const admin = `${url}/admin`;
const user = `${admin}/user`;
const host = `${admin}/host`;
const plans = `${admin}/plans`;
const body_types = `${admin}/body_types`;
const i_am = `${admin}/i_am`;
const video = `${admin}/video`;
const app_link = `${admin}/app_link`;
const app_package = `${admin}/app_package`;
const i_want = `${admin}/i_want`;
const upi = `${admin}/upi`;
const Interested_topic = `${admin}/Interested_topic`;
const transactions = `${admin}/transactions`;
const dashboard = `${admin}/dashboard`;

// Dashboard Apis
export const Dashboard_RechargeAmount_Api = `${dashboard}/RechargeAmount`;
export const Dashboard_UserTotal_Api = `${dashboard}/UserTotal`;
export const Dashboard_HostTotal_Api = `${dashboard}/HostTotal`;
export const Get_Single_Year_RechargeData_Api = `${dashboard}/GetSingleYearRechargeData`;
export const Get_One_Month_RechargeData_Api = `${dashboard}/GetOneMonthRechargeData`;
export const Host_And_User_Count_Of_TheYear_Api = `${dashboard}/HostAndUserCountOfTheYear`;
export const GetAll_Host_Pending_Amount_Api = `${dashboard}/GetAllHostPendingAmount`;
export const Pay_To_Host_Api = `${dashboard}/PayToHost`;
export const GetHostEarningReportWithFilter_Api = `${dashboard}/GetHostEarningReportWithFilter`;
export const GetDayWiseRechargeChart_Api = `${dashboard}/GetDayWiseRechargeChart`;
export const GetAllAppWiseData_Api = `${dashboard}/GetAllAppWiseData`;

// Admin Apis
export const Admin_Login_Api = `${admin}/login`;
export const Get_Admin_Data_Api = `${admin}/getadminData`;
export const Admin_Forget_Password_Email_Api = `${admin}/forgetPasswordEmail`;
export const Admin_Otp_Verification_Api = `${admin}/OtpVerification`;
export const Admin_Forget_Password_Main_Api = `${admin}/forgetpasswordMain`;
export const Admin_Change_Password_Api = `${admin}/ChangePassword`;
export const Admin_getadminData_Api = `${admin}/getadminData`;
export const Admin_UpdateProfile_Api = `${admin}/UpdateProfile`;
export const Admin_ImageUpload_Api = `${admin}/ImageUpload`;
export const GetSingleData_Admin_Setting_Api = `${admin}/GetSingleDataAdminSetting`;
export const Update_Admin_Setting_Api = `${admin}/UpdateAdminSetting`;
export const Get_Privacy_Policy_Api = `${admin}/Getprivacypolicy`;
export const Update_privacy_policy_Api = `${admin}/Updateprivacypolicy`;
export const Get_Terms_And_Condition_Api = `${admin}/GetTermsAndCondition`;
export const Update_Terms_And_Condition_Api = `${admin}/UpdateTermsAndCondition`;
export const Get_About_Us_Api = `${admin}/GetAboutUs`;
export const Update_About_Us_Api = `${admin}/UpdateAboutUs`;
export const GetCoinHistory_Api = `${admin}/GetCoinHistory`;
export const CoinfetchDataByDateRange = `${admin}/CoinfetchDataByDateRange`;

// Admin Plans Apis
export const Add_Plan_Api = `${plans}/Create`;
export const Get_All_Plan_Data_Api = `${plans}/GetAllData`;
export const Get_Single_Plan_Data_Api = `${plans}/GetSingleData`;
export const Delete_Plan_Data_Api = `${plans}/Delete`;
export const Update_Plan_Data_Api = `${plans}/Update`;

// Admin body type Apis
export const Add_body_types_Api = `${body_types}/Create`;
export const Get_All_body_types_Data_Api = `${body_types}/GetAllData`;
export const Get_Single_body_types_Data_Api = `${body_types}/GetSingleData`;
export const Delete_body_types_Data_Api = `${body_types}/Delete`;
export const Update_body_types_Data_Api = `${body_types}/Update`;

// Admin I Am Apis
export const Add_i_am_Api = `${i_am}/Create`;
export const Get_All_i_am_Data_Api = `${i_am}/GetAllData`;
export const Get_Single_i_am_Data_Api = `${i_am}/GetSingleData`;
export const Delete_i_am_Data_Api = `${i_am}/Delete`;
export const Update_i_am_Data_Api = `${i_am}/Update`;
export const i_am_DefaultSelected_Api = `${i_am}/DefaultSelected`;

// Video Apis
export const Add_video_Api = `${video}/Create`;
export const Get_All_video_Data_Api = `${video}/GetAllData`;
export const Get_Single_video_Data_Api = `${video}/GetSingleData`;
export const Delete_video_Data_Api = `${video}/Delete`;
export const Update_video_Data_Api = `${video}/Update`;

// App Link Apis
export const Add_app_link_Api = `${app_link}/Create`;
export const Get_All_app_link_Data_Api = `${app_link}/GetAllData`;
export const Get_Single_app_link_Data_Api = `${app_link}/GetSingleData`;
export const Delete_app_link_Data_Api = `${app_link}/Delete`;
export const Update_app_link_Data_Api = `${app_link}/Update`;

// App Package Apis
export const Add_app_package_Api = `${app_package}/Create`;
export const Get_All_app_package_Data_Api = `${app_package}/GetAllData`;
export const Get_Single_app_package_Data_Api = `${app_package}/GetSingleData`;
export const Delete_app_package_Data_Api = `${app_package}/Delete`;
export const Update_app_package_Data_Api = `${app_package}/Update`;

// Admin I want Apis
export const Add_i_want_Api = `${i_want}/Create`;
export const Get_All_i_want_Data_Api = `${i_want}/GetAllData`;
export const Get_Single_i_want_Data_Api = `${i_want}/GetSingleData`;
export const Delete_i_want_Data_Api = `${i_want}/Delete`;
export const Update_i_want_Data_Api = `${i_want}/Update`;
export const i_want_DefaultSelected_Api = `${i_want}/DefaultSelected`;

// Admin upi Apis
export const Add_upi_Api = `${upi}/Create`;
export const Get_All_upi_Data_Api = `${upi}/GetAllData`;
export const Get_Single_upi_Data_Api = `${upi}/GetSingleData`;
export const Delete_upi_Data_Api = `${upi}/Delete`;
export const Update_upi_Data_Api = `${upi}/Update`;

// Admin Interested Topic Apis
export const Add_Interested_topic_Api = `${Interested_topic}/Create`;
export const Get_All_Interested_topic_Data_Api = `${Interested_topic}/GetAllData`;
export const Get_Single_Interested_topic_Data_Api = `${Interested_topic}/GetSingleData`;
export const Delete_Interested_topic_Data_Api = `${Interested_topic}/Delete`;
export const Update_Interested_topic_Data_Api = `${Interested_topic}/Update`;

// host Apis
export const Add_Host_Api = `${host}/Register`;
export const Get_All_Host_Data_Api = `${host}/GetAllData`;
export const Get_Single_Host_Data_Api = `${host}/GetSingleHostdetails`;
export const Delete_Host_Data_Api = `${host}/Delete`;
export const Update_Host_Data_Api = `${host}/Update`;
export const Get_All_Host_Images_Data_Api = `${host}/GetAllImages`;
export const Insert_Multiple_Host_Image_Api = `${host}/InsertMultipleImage`;
export const Delete_Host_Images_Data_Api = `${host}/SingleImageremove`;
export const Get_Host_Call_History_Api = `${host}/GetHostCallHistory`;
export const GetHostFullDetails_Api = `${host}/GetHostFullDetails`;
export const HostCallHistoryfetchDataByDateRange_Api = `${host}/HostCallHistoryfetchDataByDateRange`;
export const SingleHostCallHistoryfetchDataByDateRange_Api = `${host}/SingleHostCallHistoryfetchDataByDateRange`;

// User Apis
export const Get_All_User_Api = `${user}/Get_All_User`;
export const Get_User_Full_Details_Api = `${user}/GetUserFullDetails`;
export const Get_Single_User_Api = `${user}/GetSingleUser`;
export const SingleUserCallHistoryfetchDataByDateRange_Api = `${user}/SingleUserCallHistoryfetchDataByDateRange`;
export const Update_User_Data_Api = `${user}/Update`;

// transactions Apis
export const GetUserTransaction_Api = `${transactions}/GetUserTransaction`;
export const UserFetchDataByDateRange_Api = `${transactions}/UserfetchDataByDateRange`;
export const GetHostCallCoinHistory_Api = `${transactions}/GetHostCallCoinHistory`;
export const ChangeHostCallHistoryStatus_Api = `${transactions}/ChangeHostCallHistoryStatus`;
export const BulkChangeHostCallHistoryStatus_Api = `${transactions}/BulkChangeHostCallHistoryStatus`;

// Images Paths
export const Admin_Image_Path = `http://localhost:${port}/Images/AdminProfile/`;
export const host_Image_Path = `http://localhost:${port}/Images/Host/Profile_Image/`;
export const App_Link_Image_Path = `http://localhost:${port}/assets/Images/appGif/`;
export const App_Package_Image_Path = `http://localhost:${port}/assets/Images/appLogo/`;
// export const Admin_Image_Path = `https://e8f0-2401-4900-1f3f-37bb-8cf2-113f-a6c3-b6c1.ngrok-free.app/Images/AdminProfile/`;  