import { GetCoinHistory_Api } from "Api/Api";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, usePagination } from 'react-table'
import axios from "axios"
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SoftButton from "components/SoftButton";
import Footer from 'examples/Footer';
import { CoinfetchDataByDateRange } from "Api/Api";


const App = () => {
    const [msg, setmsg] = useState({ msg: '', status: '' });
    const [data, setdata] = useState([]);
    const [FromDate, setFromDate] = useState(null);
    const [ToDate, setToDate] = useState(null);
    const Table_Data = [];

    const GetData = () => {
        var config = {
            method: 'post',
            url: `${GetCoinHistory_Api}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
        };
        axios(config)
            .then((response) => {
                setdata(response.data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const Date_Filter_Function = (e) => {
        var config = {
            method: 'post',
            url: `${CoinfetchDataByDateRange}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
            data: {
                startDate: FromDate,
                endDate: ToDate
            }
        };
        axios(config)
            .then((response) => {
                setdata(response.data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        GetData();
    }, []);

    function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter }, }) {
        const count = preFilteredRows.length;
        return (
            <input
                className="form-control"
                value={filterValue || ''}
                onChange={e => {
                    setFilter(e.target.value || undefined)
                }}
                placeholder={`Search ${count} records...`}
            />
        )
    }
    DefaultColumnFilter.propTypes = {
        column: PropTypes.object.isRequired,
        columns: PropTypes.array.isRequired,
        data: PropTypes.array.isRequired,
    };
    const FromDateComponent = () => {
        const [selectedDate, setSelectedDate] = useState(null);
        const handleDateChange = (date) => {
            console.log(date)
            setFromDate(date);
        };
        return (
            <div>
                <DatePicker
                    selected={FromDate}
                    onChange={handleDateChange}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Select Date"
                    className="form-control"
                />
            </div>
        );
    };

    const ToDateComponent = () => {
        const [selectedDate, setSelectedDate] = useState(null);
        const handleDateChange = (date) => {
            setToDate(date);
        };
        return (
            <div>
                <DatePicker
                    selected={ToDate}
                    onChange={handleDateChange}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Select Date"
                    className="form-control"
                />
            </div>
        );
    };

    function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
        const count = preGlobalFilteredRows.length
        const [value, setValue] = React.useState(globalFilter)
        const onChange = useAsyncDebounce(value => {
            setGlobalFilter(value || undefined)
        }, 200)

        return (
            <div className="d-flex mb-3 align-items-end">
                <span className="col-6 px-2">
                    Search:{' '}
                    <input
                        className="form-control"
                        value={value || ""}
                        onChange={e => {
                            setValue(e.target.value);
                            onChange(e.target.value);
                        }}
                        placeholder={`${count} records...`}
                    />
                </span>
                <div className="d-flex px-2 align-items-center">
                    <h6 className="me-2">From :</h6> <FromDateComponent /> <h6 className="mx-2">To :</h6> <ToDateComponent />
                    <SoftButton variant="gradient" type="button" onClick={Date_Filter_Function} color="info" >
                        Filter
                    </SoftButton>
                </div>
            </div>
        )
    }

    GlobalFilter.propTypes = {
        preGlobalFilteredRows: PropTypes.array.isRequired,
        globalFilter: PropTypes.string.isRequired,
        setGlobalFilter: PropTypes.func.isRequired,
    };

    const Table = ({ columns, data }) => {
        const defaultColumn = React.useMemo(
            () => ({
                Filter: DefaultColumnFilter,
            }),
            []
        )
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            prepareRow,
            page,
            state,
            canPreviousPage,
            canNextPage,
            pageOptions,
            pageCount,
            gotoPage,
            nextPage,
            previousPage,
            setPageSize,
            preGlobalFilteredRows,
            setGlobalFilter,
            state: { pageIndex, pageSize },
        } = useTable(
            {
                columns,
                data,
                defaultColumn,
                initialState: { pageIndex: 0, pageSize: 5 },
            },
            useGlobalFilter,
            useFilters,
            usePagination,
        )
        return (
            <div>
                <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />
                <table className="table" {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup, key) => (
                            <tr {...headerGroup.getHeaderGroupProps()} key={''}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps()} key={''} className="Table_Title">
                                        {column.render('Header')}
                                        <div>{column.canFilter ? column.render('Filter') : null}</div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()} key={''} className="Table_data_tr">
                                    {row.cells.map(cell => {
                                        return <td {...cell.getCellProps()} key={''}>{cell.render('Cell')}</td>
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <ul className="pagination float-end ">
                    <li className="page-item cursor-pointor" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        <a className="page-link text-dark">First</a>
                    </li>
                    <li className="page-item cursor-pointor" onClick={() => previousPage()} disabled={!canPreviousPage}>
                        <a className="page-link text-dark">{'<'}</a>
                    </li>
                    <li className="page-item cursor-pointor" onClick={() => nextPage()} disabled={!canNextPage}>
                        <a className="page-link text-dark">{'>'}</a>
                    </li>
                    <li className="page-item cursor-pointor" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        <a className="page-link text-dark">Last</a>
                    </li>
                    <li>
                        <a className="page-link text-dark cursor-pointor">
                            Page{' '}
                            <strong>
                                {pageIndex + 1} of {pageOptions.length}
                            </strong>{' '}
                        </a>
                    </li>
                    <select
                        className="form-control cursor-pointor"
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value))
                        }}
                        style={{ width: '120px', height: '38px' }}
                    >
                        {[5, 10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                </ul>
            </div>
        )
    }

    Table.propTypes = {
        columns: PropTypes.object.isRequired,
        data: PropTypes.array.isRequired,
    };


    data.map((item, index) => {
        Table_Data.push({
            "user_name": item.user_name,
            "host_name": item.host_name,
            "User_Coin": item.User_Coin,
            "Host_Coin": item.Host_Coin,
            "duration": item.duration,
            "call_status": item.call_status,
            "created_at": new Date(item.created_at).toLocaleString(),
            "updated_at": new Date(item.updated_at).toLocaleString(),
        })
    });

    const columns = React.useMemo(
        () => [
            {
                Header: 'User Name',
                accessor: 'user_name',
            },
            {
                Header: 'Host Name',
                accessor: 'host_name',
            },
            {
                Header: 'User Coin',
                accessor: 'User_Coin',
            },
            {
                Header: 'Host Coin',
                accessor: 'Host_Coin',
            },
            {
                Header: 'Call Duration',
                accessor: 'duration',
            },
            {
                Header: 'Call Status',
                accessor: 'call_status',
            },
            {
                Header: 'Created At',
                accessor: 'created_at',
            },
            {
                Header: 'Updated At',
                accessor: 'updated_at',
            },
        ],
        []
    );

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div className='container min-vh-For_Footer' style={{ paddingTop: '2%' }}>
                <div className='row justify-content-center'>
                    <Table columns={columns} data={Table_Data} />
                </div>
            </div>
            <Footer />
        </DashboardLayout>
    )
}

export default App;