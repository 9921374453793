import React, { useEffect, useRef, useState } from 'react'
import Footer from 'examples/Footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftInput from 'components/SoftInput';
import SoftButton from 'components/SoftButton';
import Alert from "assets/Custom_alert/Custom_Alert";
import $ from 'jquery';
import 'jquery-validation';
import axios from 'axios';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { TagsInput } from 'react-tag-input-component';
import SoftAvatar from 'components/SoftAvatar';
import { Get_Single_app_link_Data_Api } from 'Api/Api';
import { Update_app_link_Data_Api } from 'Api/Api';

const Update = () => {
    const animatedComponents = makeAnimated();
    const [msg, setmsg] = useState({ msg: '', status: '' });
    const [data, setdata] = useState({});
    const formRef = useRef(null);
    const [PreviewImage, setPreviewImage] = useState();
    const [Images, setImages] = useState();
    const id = new URLSearchParams(window.location.search).get('id');
    useEffect(() => {
        GetData();
    }, []);
    const GetData = () => {
        var config = {
            method: 'post',
            url: `${Get_Single_app_link_Data_Api}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
            data: { id: id }
        };
        axios(config)
            .then((response) => {
                let result = response.data.data;
                setdata(result);
                setPreviewImage(result.app_gif);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        $(formRef.current).validate({
            rules: {
                applink: "required"
            },
            messages: {
                applink: "Please enter a app link"
            },
            errorPlacement: function (error, element) {
                error.insertAfter($(element).closest(".newerror"));
            },
            submitHandler: (form) => {
                let n_form = new FormData(form);
                var config = {
                    method: 'post',
                    url: `${Update_app_link_Data_Api}`,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    },
                    data: n_form,
                };
                axios(config)
                    .then((response) => {
                        if (response.data.success === true) {
                            setmsg({ msg: response.data.message, status: 'success' });
                        } else {
                            setmsg({ msg: response.data.message, status: 'error' });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            },
        });
    }, [])
    const SingleImageHandler = (e) => {
        setImages(e.target.files[0]);
        if(e.target.files[0]){
            setPreviewImage(URL.createObjectURL(e.target.files[0]));
        } else {
            setPreviewImage(data.app_gif);
        }
    }
    return (
        <>
            {msg.msg && (
                <>
                    <div className="alert-container">
                        <Alert msg={msg.msg} status={msg.status} />
                    </div>
                    <div className='d-none'>
                        {setTimeout(() => {
                            setmsg({ msg: '', status: '' })
                        }, 3000)}
                    </div>
                </>
            )}
            <DashboardLayout>
                <DashboardNavbar />
                <div className='container min-vh-For_Footer' style={{ paddingTop: '2%' }}>
                    <div className='row justify-content-center'>
                        <div className='col-6'>
                            <SoftBox component="form" role="form" ref={formRef} id="myForm" className="d-flex flex-wrap">
                                <div className='col-12 px-2'>
                                    <SoftBox className="newerror">
                                        <h5>Profile Image</h5>
                                        <SoftAvatar
                                            src={PreviewImage ? `${PreviewImage}` : 'https://annemariesegal.files.wordpress.com/2017/06/img_0422-linkedin-size-smiling-man-in-suit.png?w=840&h=831'}
                                            alt="profile-image"
                                            variant="rounded"
                                            size="xl"
                                            shadow="sm"
                                            className="mb-3"
                                        />
                                        <SoftButton variant="gradient" className="me-3 position-relative" color="secondary">
                                            <input type="file" name="image" className="custom_Upload_Images" onChange={SingleImageHandler} />
                                            Choose Image
                                        </SoftButton>
                                    </SoftBox>
                                </div>
                                <div className='col-12 px-2'>
                                    <SoftBox className="newerror">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                App Link
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="text" placeholder="App link" value={data.applink} name="applink" />
                                        <input type="hidden" placeholder="id" value={data.id} name="id" />
                                    </SoftBox>
                                </div>
                                <SoftBox mt={2} mb={1} className="col-12 text-center">
                                    <SoftButton variant="gradient" type="submit" color="info">
                                        Update App Link 
                                    </SoftButton>
                                </SoftBox>
                            </SoftBox>
                        </div>
                    </div>
                </div>
                <Footer />
            </DashboardLayout>
        </>
    )
}

export default Update;