import { GetCoinHistory_Api } from "Api/Api";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, usePagination } from 'react-table'
import axios from "axios"
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Alert from "assets/Custom_alert/Custom_Alert";
import SoftButton from "components/SoftButton";
import Footer from 'examples/Footer';
import { GetHostCallCoinHistory_Api, ChangeHostCallHistoryStatus_Api } from "Api/Api";
import { MenuItem, Select } from "@mui/material";
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import { BulkChangeHostCallHistoryStatus_Api } from "Api/Api";


const App = () => {
    const [msg, setmsg] = useState({ msg: '', status: '' });
    const [data, setdata] = useState([]);
    const [allSelect, setAllSelect] = useState(false);
    const [FromDate, setFromDate] = useState(null);
    const [ToDate, setToDate] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState('');
    const Table_Data = [];

    const GetData = () => {
        var config = {
            method: 'get',
            url: `${GetHostCallCoinHistory_Api}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
        };
        axios(config)
            .then((response) => {
                setdata(response.data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        GetData();
    }, []);

    const ChangeStatusFunction = (e, id) => {
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: ChangeHostCallHistoryStatus_Api,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
            data: {
                id: id,
                status: e.target.value
            }
        };

        axios.request(config)
            .then((response) => {
                let result = response.data
                if (result.success == true) {
                    setmsg({ msg: result.message, status: 'success' });
                    GetData();
                } else {
                    setmsg({ msg: result.message, status: 'error' });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter }, }) {
        const count = preFilteredRows.length;
        return (
            <input
                className="form-control"
                value={filterValue || ''}
                onChange={e => {
                    setFilter(e.target.value || undefined)
                }}
                placeholder={`Search ${count} records...`}
            />
        )
    }
    DefaultColumnFilter.propTypes = {
        column: PropTypes.object.isRequired,
        columns: PropTypes.array.isRequired,
        data: PropTypes.array.isRequired,
    };
    const Table = ({ columns, data }) => {
        const defaultColumn = React.useMemo(
            () => ({
                Filter: DefaultColumnFilter,
            }),
            []
        )
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            prepareRow,
            page,
            canPreviousPage,
            canNextPage,
            pageOptions,
            pageCount,
            gotoPage,
            nextPage,
            previousPage,
            setPageSize,
            state: { pageIndex, pageSize },
        } = useTable(
            {
                columns,
                data,
                defaultColumn,
                initialState: { pageIndex: 0, pageSize: 5 },
            },
            useGlobalFilter,
            useFilters,
            usePagination,
        )
        return (
            <div>
                <table className="table" {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup, key) => (
                            <tr {...headerGroup.getHeaderGroupProps()} key={key}>
                                <th scope="col">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        name="allSelect"
                                        id="allSelect"
                                        checked={allSelect || false}
                                        onChange={handleChange}
                                    />
                                </th>
                                {headerGroup.headers.map((column, index) => (
                                    <th {...column.getHeaderProps()} key={index} className="Table_Title">
                                        {column.render('Header')}
                                        <div>{column.canFilter ? column.render('Filter') : null}</div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()} key={i} className="Table_data_tr">
                                    <td scope="row">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            name={row.original.id}
                                            checked={row.original.isChecked || false}
                                            onChange={handleChange}
                                        />
                                    </td>
                                    {row.cells.map(cell => {
                                        return <td {...cell.getCellProps()} key={cell.column.id}>{cell.render('Cell')}</td>
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <ul className="pagination float-end ">
                    <li className="page-item cursor-pointor" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        <a className="page-link text-dark">First</a>
                    </li>
                    <li className="page-item cursor-pointor" onClick={() => previousPage()} disabled={!canPreviousPage}>
                        <a className="page-link text-dark">{'<'}</a>
                    </li>
                    <li className="page-item cursor-pointor" onClick={() => nextPage()} disabled={!canNextPage}>
                        <a className="page-link text-dark">{'>'}</a>
                    </li>
                    <li className="page-item cursor-pointor" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        <a className="page-link text-dark">Last</a>
                    </li>
                    <li>
                        <a className="page-link text-dark cursor-pointor">
                            Page{' '}
                            <strong>
                                {pageIndex + 1} of {pageOptions.length}
                            </strong>{' '}
                        </a>
                    </li>
                    <select
                        className="form-control cursor-pointor"
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value))
                        }}
                        style={{ width: '120px', height: '38px' }}
                    >
                        {[5, 10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                Show {pageSize}
                            </option>
                        ))}
                    </select>
                </ul>
            </div>
        )
    }

    Table.propTypes = {
        columns: PropTypes.object.isRequired,
        data: PropTypes.array.isRequired,
    };


    data.map((item, index) => {
        Table_Data.push({
            "id": item.id,
            "host_id": item.host_id,
            "coin": item.coin,
            "status": <SoftBox className="newerror px-2">
                <Select
                    name="reaction_like"
                    value={item.status}
                    onChange={(e) => ChangeStatusFunction(e, item.id)}
                >
                    <MenuItem value={'pending'}>pending</MenuItem>
                    <MenuItem value={'rejected'}>rejected</MenuItem>
                    <MenuItem value={'success'}>success</MenuItem>
                </Select>
            </SoftBox>,
            "created_at": new Date(item.created_at).toLocaleString(),
            "updated_at": new Date(item.updated_at).toLocaleString(),
            "isChecked": item.isChecked || false, // Initialize isChecked
        })
    });


    const columns = React.useMemo(
        () => [
            {
                Header: 'Id',
                accessor: 'id',
            },
            {
                Header: 'Host Id',
                accessor: 'host_id',
            },
            {
                Header: 'Coin',
                accessor: 'coin',
            },
            {
                Header: 'Status',
                accessor: 'status',
            },
            {
                Header: 'Created At',
                accessor: 'created_at',
            },
            {
                Header: 'Updated At',
                accessor: 'updated_at',
            },
        ],
        []
    );

    const handleBulkStatusChange = () => {
        const selectedIds = data.filter(item => item.isChecked).map(item => item.id);

        if (selectedIds.length === 0) {
            setmsg({ msg: 'No rows selected', status: 'error' });
            return;
        }

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: BulkChangeHostCallHistoryStatus_Api,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
            data: {
                ids: selectedIds,
                status: selectedStatus
            }
        };

        axios.request(config)
            .then((response) => {
                let result = response.data
                if (result.success === true) {
                    setmsg({ msg: result.message, status: 'success' });
                    GetData();
                    setAllSelect(false);
                    setSelectedStatus('');
                } else {
                    setmsg({ msg: result.message, status: 'error' });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleChange = (e) => {
        const { name, checked } = e.target;

        if (name === "allSelect") {
            setAllSelect(checked);
            let tempData = data.map(item => {
                return { ...item, isChecked: checked };
            });
            setdata(tempData);
        } else {
            let tempData = data.map(item =>
                item.id === parseInt(name) ? { ...item, isChecked: checked } : item
            );
            setdata(tempData);
            setAllSelect(false);
        }
    }

    return (
        <>
            {msg.msg && (
                <>
                    <div className="alert-container">
                        <Alert msg={msg.msg} status={msg.status} />
                    </div>
                    <div className='d-none'>
                        {setTimeout(() => {
                            setmsg({ msg: '', status: '' })
                        }, 3000)}
                    </div>
                </>
            )}
            <DashboardLayout>
                <DashboardNavbar />
                <div className='container min-vh-For_Footer' style={{ paddingTop: '2%' }}>
                    <div className='row justify-content-center'>
                        <div className="col-md-12 mb-3">
                            <SoftBox className="newerror px-2 d-flex">
                                <div className="col-9">
                                    <Select
                                        value={selectedStatus}
                                        onChange={(e) => setSelectedStatus(e.target.value)}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Bulk Status' }}
                                    >
                                        <MenuItem value="" disabled>Select Status</MenuItem>
                                        <MenuItem value={'pending'}>pending</MenuItem>
                                        <MenuItem value={'rejected'}>rejected</MenuItem>
                                        <MenuItem value={'success'}>success</MenuItem>
                                    </Select>
                                </div>
                                <div className="col-3">
                                    <SoftButton onClick={handleBulkStatusChange} color="primary" className="ms-3">
                                        Update Selected Status
                                    </SoftButton>
                                </div>
                            </SoftBox>
                        </div>

                        <Table columns={columns} data={Table_Data} />
                    </div>
                </div>
                <Footer />
            </DashboardLayout>
        </>
    )
}

export default App;