import React, { useEffect, useRef, useState } from 'react'
import Footer from 'examples/Footer';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftInput from 'components/SoftInput';
import SoftButton from 'components/SoftButton';
import Alert from "assets/Custom_alert/Custom_Alert";
import $ from 'jquery';
import 'jquery-validation';
import axios from 'axios';
import { Update_Admin_Setting_Api, GetSingleData_Admin_Setting_Api } from 'Api/Api';
import { MenuItem, Select } from '@mui/material';

const AdminSetting = () => {
    const [msg, setmsg] = useState({ msg: '', status: '' });
    const [data, setdata] = useState({});
    const formRef = useRef(null);
    const agoraUpdateRef = useRef(null);
    const videoCallSettingUpdateRef = useRef(null);
    const chargesUpdateRef = useRef(null);
    const appModelUpdateRef = useRef(null);
    const socialPluginUpdateRef = useRef(null);
    const Admin_Data = JSON.parse(localStorage.getItem('data'));
    const GetData = () => {
        var config = {
            method: 'post',
            url: `${GetSingleData_Admin_Setting_Api}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
            data: { id: Admin_Data.id }
        };
        axios(config)
            .then((response) => {
                setdata(response.data.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }
    useEffect(() => {
        GetData();
        $(formRef.current).validate({
            rules: {
                id: "required",
                upi: "required",
                seconds: "required",
                agora_id: "required",
                starting_day: "required",
                max_seconds_for_call: "required",
                user_call_coin_rate: "required",
                host_coin_rate: "required",
                app_link: "required",
                open_model: "required",
                btn_text: "required",
                description: "required",
                Download_link: "required",
                shop_now_btn: "required",
                reaction_like: "required",
                reaction_gif: "required",
                reaction_download: "required",
                shop_now_visiblity: "required",
                call_seconds: "required",
                call_rate: "required"
            },
            messages: {
                id: "Something Went Wrong",
                upi: "Please enter a upi",
                seconds: "Please enter a seconds",
                agora_id: "Please enter a Agora Id",
                starting_day: "Please enter a Starting Day",
                max_seconds_for_call: "Please enter a Max Seconds For Call",
                user_call_coin_rate: "Please enter a User Call Coin Rate",
                host_coin_rate: "Please enter a Host Coin Rate",
                app_link: "Please Enter Application Link",
                open_model: "Please Enter Number that open model when user scroll",
                btn_text: "Please Enter Button Text",
                description: "Please Enter Applicatin Description",
                Download_link: "Please Enter Applicatin Download LinK",
                shop_now_btn: "Plese Enter Shop Now Button Text",
                reaction_like: "Please Select Like is show or not",
                reaction_gif: "Please Select Gif is show or not",
                reaction_download: "Please Select Download button is show or not",
                shop_now_visiblity: "Please Select shop now button is show or not",
                call_seconds: "Please Enter Call Seconds",
                call_rate: "Please Enter Call Rate"
            },
            errorPlacement: function (error, element) {
                error.insertAfter($(element).closest(".newerror"));
            },
            submitHandler: (form) => {
                let n_form = $(form).serialize();
                var config = {
                    method: 'post',
                    url: `${Update_Admin_Setting_Api}`,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    },
                    data: n_form,
                };
                axios(config)
                    .then((response) => {
                        if (response.data.success == true) {
                            setmsg({ msg: response.data.message, status: 'success' });
                            setTimeout(() => {
                                window.location.reload();
                            }, 2000);
                        } else {
                            setmsg({ msg: response.data.message, status: 'error' });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            },
        });
        $('.css-1i0az6v-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input').attr("style", "width:100% !important;padding:8px 28px 8px 12px !important");
        $('.css-1qlam7x-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root').attr("style", "padding:0 !important");
    }, []);
    const handleChange = (e) => {
        setdata((data) => ({
            ...data,
            [e.target.name]: e.target.value,
        }));
    }

    $(agoraUpdateRef.current).validate({
        rules: {
            agora_id: "required",
            agora_certificate: "required",
        },
        messages: {
            agora_id: "Please enter a Agora Id",
            agora_certificate: "Please enter a Agora Certificate",
        },
        errorPlacement: function (error, element) {
            error.insertAfter($(element).closest(".newerror"));
        },
        submitHandler: (form) => {
            let n_form = $(form).serialize();
            var config = {
                method: 'post',
                url: `${Update_Admin_Setting_Api}`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                data: n_form,
            };
            axios(config)
                .then((response) => {
                    if (response.data.success == true) {
                        setmsg({ msg: response.data.message, status: 'success' });
                        setTimeout(() => {
                            window.location.reload();
                        }, 2000);
                    } else {
                        setmsg({ msg: response.data.message, status: 'error' });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    })

    $(videoCallSettingUpdateRef.current).validate({
        rules: {
            call_seconds: "required",
            open_model: "required",
        },
        messages: {
            call_seconds: "Please enter a Max Call Seconds",
            open_model: "Please enter a Open Model",
        },
        errorPlacement: function (error, element) {
            error.insertAfter($(element).closest(".newerror"));
        },
        submitHandler: (form) => {
            let n_form = $(form).serialize();
            var config = {
                method: 'post',
                url: `${Update_Admin_Setting_Api}`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                data: n_form,
            };
            axios(config)
                .then((response) => {
                    if (response.data.success == true) {
                        setmsg({ msg: response.data.message, status: 'success' });
                        setTimeout(() => {
                            window.location.reload();
                        }, 2000);
                    } else {
                        setmsg({ msg: response.data.message, status: 'error' });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    })

    $(chargesUpdateRef.current).validate({
        rules: {
            user_call_coin_rate: "required",
            host_coin_rate: "required",
            host_coin_multiply: "required",
            vehicle_info_payment: "required",
        },
        messages: {
            user_call_coin_rate: "Please enter a Video Call Charge from User",
            host_coin_rate: "Please enter a Video Call Charge To Host",
            host_coin_multiply: "Please enter a Host Rate Multiply",
            vehicle_info_payment: "Please Select Vehicle Info Payment",
        },
        errorPlacement: function (error, element) {
            error.insertAfter($(element).closest(".newerror"));
        },
        submitHandler: (form) => {
            let n_form = $(form).serialize();
            var config = {
                method: 'post',
                url: `${Update_Admin_Setting_Api}`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                data: n_form,
            };
            axios(config)
                .then((response) => {
                    if (response.data.success == true) {
                        setmsg({ msg: response.data.message, status: 'success' });
                        setTimeout(() => {
                            window.location.reload();
                        }, 2000);
                    } else {
                        setmsg({ msg: response.data.message, status: 'error' });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    })

    $(appModelUpdateRef.current).validate({
        rules: {
            app_link: "required",
            btn_text: "required",
            description: "required",
            Download_link: "required",
            shop_now_btn: "required",
        },
        messages: {
            app_link: "Please enter a App Link",
            btn_text: "Please enter a Button Text",
            description: "Please enter a App Description",
            Download_link: "Please enter a App Download Link",
            shop_now_btn: "Please enter a Shop Now Button Text",
        },
        errorPlacement: function (error, element) {
            error.insertAfter($(element).closest(".newerror"));
        },
        submitHandler: (form) => {
            let n_form = $(form).serialize();
            var config = {
                method: 'post',
                url: `${Update_Admin_Setting_Api}`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                data: n_form,
            };
            axios(config)
                .then((response) => {
                    if (response.data.success == true) {
                        setmsg({ msg: response.data.message, status: 'success' });
                        setTimeout(() => {
                            window.location.reload();
                        }, 2000);
                    } else {
                        setmsg({ msg: response.data.message, status: 'error' });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    })

    $(socialPluginUpdateRef.current).validate({
        rules: {
            reaction_like: "required",
            reaction_gif: "required",
            reaction_download: "required",
            shop_now_visiblity: "required",
        },
        messages: {
            reaction_like: "Please Select Like Btn Status",
            reaction_gif: "Please Select GIF Btn Status",
            reaction_download: "Please Select Download Btn Status",
            shop_now_visiblity: "Please Select Shop Now Btn Status",
        },
        errorPlacement: function (error, element) {
            error.insertAfter($(element).closest(".newerror"));
        },
        submitHandler: (form) => {
            let n_form = $(form).serialize();
            var config = {
                method: 'post',
                url: `${Update_Admin_Setting_Api}`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                },
                data: n_form,
            };
            axios(config)
                .then((response) => {
                    if (response.data.success == true) {
                        setmsg({ msg: response.data.message, status: 'success' });
                        setTimeout(() => {
                            window.location.reload();
                        }, 2000);
                    } else {
                        setmsg({ msg: response.data.message, status: 'error' });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    })


    return (
        <>
            {msg.msg && (
                <>
                    <div className="alert-container">
                        <Alert msg={msg.msg} status={msg.status} />
                    </div>
                    <div className='d-none'>
                        {setTimeout(() => {
                            setmsg({ msg: '', status: '' })
                        }, 3000)}
                    </div>
                </>
            )
            }
            <DashboardLayout>
                <DashboardNavbar />

                {/* <div className='container min-vh-For_Footer' style={{ paddingTop: '5%' }}>
                    <div className='row justify-content-center'>
                        <div className='col-10'>
                            <SoftBox component="form" role="form" ref={formRef} id="myForm" className="d-flex" style={{ flexWrap: 'wrap' }}>
                                <div className='col-6'>
                                    <SoftBox className="newerror px-2">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Upi
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="text" placeholder="upi" value={data.upi} name="upi" disabled />
                                    </SoftBox>
                                    <SoftBox className="newerror px-2 d-none">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                id
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="text" placeholder="id" value={Admin_Data.id} name="id" />
                                    </SoftBox>
                                </div>
                                <div className='col-6'>
                                    <SoftBox className="newerror px-2">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                seconds
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="text" placeholder="seconds" value={data.seconds} name="seconds" onChange={handleChange} />
                                    </SoftBox>
                                </div>
                                <div className='col-6'>
                                    <SoftBox className="newerror px-2">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Agora Id
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="text" placeholder="Agora Id" value={data.agora_id} name="agora_id" onChange={handleChange} />
                                    </SoftBox>
                                </div>
                                <div className='col-6'>
                                    <SoftBox className="newerror px-2">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Starting Day
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="date" placeholder="Starting Day" value={data.starting_day} name="starting_day" onChange={handleChange} />
                                    </SoftBox>
                                </div>
                                <div className='col-6'>
                                    <SoftBox className="newerror px-2">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Max Seconds For Call
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="number" placeholder="Max Seconds For Call" value={data.max_seconds_for_call} name="max_seconds_for_call" onChange={handleChange} />
                                    </SoftBox>
                                </div>
                                <div className='col-6'>
                                    <SoftBox className="newerror px-2">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                User Call Coin Rate
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="number" placeholder="User Call Coin Rate" value={data.user_call_coin_rate} name="user_call_coin_rate" onChange={handleChange} />
                                    </SoftBox>
                                </div>
                                <div className='col-6'>
                                    <SoftBox className="newerror px-2">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Host Coin Rate
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="number" placeholder="Host Coin Rate" value={data.host_coin_rate} name="host_coin_rate" onChange={handleChange} />
                                    </SoftBox>
                                </div>
                                <div className='col-6'>
                                    <SoftBox className="newerror px-2">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Open Model
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="number" placeholder="Model Open" value={data.open_model} name="open_model" onChange={handleChange} />
                                    </SoftBox>
                                </div>
                                <div className='col-6'>
                                    <SoftBox className="newerror px-2">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                App Link
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="text" placeholder="App Link" value={data.app_link} name="app_link" onChange={handleChange} />
                                    </SoftBox>
                                </div>
                                <div className='col-6'>
                                    <SoftBox className="newerror px-2">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Button Text
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="text" placeholder="Button Text" value={data.btn_text} name="btn_text" onChange={handleChange} />
                                    </SoftBox>
                                </div>
                                <div className='col-6'>
                                    <SoftBox className="newerror px-2">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Description
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="text" placeholder="Description" value={data.description} name="description" onChange={handleChange} />
                                    </SoftBox>
                                </div>
                                <div className='col-6'>
                                    <SoftBox className="newerror px-2">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Video Download Link
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="text" placeholder="Video Download Link" value={data.Download_link} name="Download_link" onChange={handleChange} />
                                    </SoftBox>
                                </div>
                                <div className='col-6'>
                                    <SoftBox className="newerror px-2">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Shop Now Button Text
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="text" placeholder="Shop Now Button Text" value={data.shop_now_btn} name="shop_now_btn" onChange={handleChange} />
                                    </SoftBox>
                                </div>
                                <div className='col-6'>
                                    <SoftBox className="newerror px-2">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Like
                                            </SoftTypography>
                                        </SoftBox>
                                        <Select
                                            name="reaction_like"
                                            value={data.reaction_like ? data.reaction_like : 0}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={0}>No</MenuItem>
                                            <MenuItem value={1}>Yes</MenuItem>
                                        </Select>
                                    </SoftBox>
                                </div>
                                <div className='col-6'>
                                    <SoftBox className="newerror px-2">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                GIF
                                            </SoftTypography>
                                        </SoftBox>
                                        <Select
                                            name="reaction_gif"
                                            value={data.reaction_gif ? data.reaction_gif : 0}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={0}>No</MenuItem>
                                            <MenuItem value={1}>Yes</MenuItem>
                                        </Select>
                                    </SoftBox>
                                </div>
                                <div className='col-6'>
                                    <SoftBox className="newerror px-2">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Download
                                            </SoftTypography>
                                        </SoftBox>
                                        <Select
                                            name="reaction_download"
                                            value={data.reaction_download ? data.reaction_download : 0}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={0}>No</MenuItem>
                                            <MenuItem value={1}>Yes</MenuItem>
                                        </Select>
                                    </SoftBox>
                                </div>
                                <div className='col-6'>
                                    <SoftBox className="newerror px-2">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Shop now button visiblity
                                            </SoftTypography>
                                        </SoftBox>
                                        <Select
                                            name="shop_now_visiblity"
                                            value={data.shop_now_visiblity ? data.shop_now_visiblity : 0}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={0}>No</MenuItem>
                                            <MenuItem value={1}>Yes</MenuItem>
                                        </Select>
                                    </SoftBox>
                                </div>
                                <div className='col-6'>
                                    <SoftBox className="newerror px-2">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Call Seconds
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="text" placeholder="Call Seconds" value={data.call_seconds} name="call_seconds" onChange={handleChange} />
                                    </SoftBox>
                                </div>
                                <div className='col-6'>
                                    <SoftBox className="newerror px-2">
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Call Rate
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftInput type="text" placeholder="Call Rate" value={data.call_rate} name="call_rate" onChange={handleChange} />
                                    </SoftBox>
                                </div>
                                <SoftBox mt={2} mb={1} className="col-12 text-center">
                                    <SoftButton variant="gradient" type="submit" color="info">
                                        Update Setting
                                    </SoftButton>
                                </SoftBox>
                            </SoftBox>
                        </div>
                    </div>
                </div> */}


                <div className="container">
                    <div className="row flex-wrap">
                        <div className='col-6 p-2'>
                            <div className="setting-input-wrapper">
                                <h5>Set Id & Certificate</h5>
                                <div className='custom-devider'></div>
                                <form id='agoraUpdate' ref={agoraUpdateRef}>
                                    <input type="hidden" value={Admin_Data.id} name="id" readOnly />
                                    <div className='p-4 d-flex flex-wrap'>
                                        <div className='col-6'>
                                            <SoftBox className="newerror pe-2">
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Agora Id
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftInput type="text" placeholder="Agora Id" value={data.agora_id} name="agora_id" onChange={handleChange} />
                                            </SoftBox>
                                        </div>
                                        <div className='col-6'>
                                            <SoftBox className="newerror ps-2">
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Agora Certificate
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftInput type="text" placeholder="Agora Certificate" value={data.agora_certificate} name="agora_certificate" onChange={handleChange} />
                                            </SoftBox>
                                        </div>
                                        <SoftBox mt={2} mb={1} className="col-12 text-end">
                                            <SoftButton variant="gradient" type="submit" color="info">
                                                Submit
                                            </SoftButton>
                                        </SoftBox>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className='col-6 p-2'>
                            <div className="setting-input-wrapper">
                                <h5>Video Call Setting</h5>
                                <div className='custom-devider'></div>
                                <form id="videoCallSettingUpdate" ref={videoCallSettingUpdateRef}>
                                    <input type="hidden" value={Admin_Data.id} name="id" readOnly />
                                    <div className='p-4 d-flex flex-wrap'>
                                        <div className='col-6'>
                                            <SoftBox className="newerror pe-2">
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Max Call Seconds
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftInput type="text" placeholder="Max Call Seconds" value={data.call_seconds} name="call_seconds" onChange={handleChange} />
                                            </SoftBox>
                                        </div>
                                        <div className='col-6'>
                                            <SoftBox className="newerror pe-2">
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Open Model
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftInput type="text" placeholder="Model Count" value={data.open_model} name="open_model" onChange={handleChange} />
                                            </SoftBox>
                                        </div>
                                        <SoftBox mt={2} mb={1} className="col-12 text-end">
                                            <SoftButton variant="gradient" type="submit" color="info">
                                                Submit
                                            </SoftButton>
                                        </SoftBox>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="row flex-wrap">
                        <div className='col-6 p-2'>
                            <div className="setting-input-wrapper">
                                <h5>Charges for Host & User</h5>
                                <div className='custom-devider'></div>
                                <form id='chargesUpdate' ref={chargesUpdateRef}>
                                    <input type="hidden" value={Admin_Data.id} name="id" readOnly />
                                    <div className='p-4 d-flex flex-wrap'>
                                        <div className='col-6'>
                                            <SoftBox className="newerror pe-2">
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Video Call Charge from User
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftInput type="text" placeholder="Video Call Charge from User" value={data.user_call_coin_rate} name="user_call_coin_rate" onChange={handleChange} />
                                            </SoftBox>
                                        </div>
                                        <div className='col-6'>
                                            <SoftBox className="newerror pe-2">
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Video Call Charge To Host
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftInput type="text" placeholder="Video Call Charge To Host" value={data.host_coin_rate} name="host_coin_rate" onChange={handleChange} />
                                            </SoftBox>
                                        </div>
                                        <div className='col-6'>
                                            <SoftBox className="newerror pe-2">
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Host Rate Multiply
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftInput type="text" placeholder="Host Rate Multiply" value={data.host_coin_multiply} name="host_coin_multiply" onChange={handleChange} />
                                            </SoftBox>
                                        </div>
                                        <div className='col-6'>
                                            <SoftBox className="newerror px-2">
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Vehicle Info Payment
                                                    </SoftTypography>
                                                </SoftBox>
                                                <Select
                                                    name="vehicle_info_payment"
                                                    value={data.vehicle_info_payment ? data.vehicle_info_payment : 0}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={0}>No</MenuItem>
                                                    <MenuItem value={1}>Yes</MenuItem>
                                                </Select>
                                            </SoftBox>
                                        </div>
                                        <SoftBox mt={2} mb={1} className="col-12 text-end">
                                            <SoftButton variant="gradient" type="submit" color="info">
                                                Submit
                                            </SoftButton>
                                        </SoftBox>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className='col-6 p-2'>
                            <div className="setting-input-wrapper">
                                <h5>App Model</h5>
                                <div className='custom-devider'></div>
                                <form id='appModelUpdate' ref={appModelUpdateRef}>
                                    <input type="hidden" value={Admin_Data.id} name="id" readOnly />
                                    <div className='p-4 d-flex flex-wrap'>
                                        <div className='col-6'>
                                            <SoftBox className="newerror pe-2">
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        App Link
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftInput type="text" placeholder="App Link" value={data.app_link} name="app_link" onChange={handleChange} />
                                            </SoftBox>
                                        </div>
                                        <div className='col-6'>
                                            <SoftBox className="newerror pe-2">
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Button Text
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftInput type="text" placeholder="Button Text" value={data.btn_text} name="btn_text" onChange={handleChange} />
                                            </SoftBox>
                                        </div>
                                        <div className='col-6'>
                                            <SoftBox className="newerror pe-2">
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Description
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftInput type="text" placeholder="Description" value={data.description} name="description" onChange={handleChange} />
                                            </SoftBox>
                                        </div>
                                        <div className='col-6'>
                                            <SoftBox className="newerror pe-2">
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Video Download Link
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftInput type="text" placeholder="Video Download Link" value={data.Download_link} name="Download_link" onChange={handleChange} />
                                            </SoftBox>
                                        </div>
                                        <div className='col-6'>
                                            <SoftBox className="newerror pe-2">
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Shop Now Button Text
                                                    </SoftTypography>
                                                </SoftBox>
                                                <SoftInput type="text" placeholder="Shop Now Button Text" value={data.shop_now_btn} name="shop_now_btn" onChange={handleChange} />
                                            </SoftBox>
                                        </div>
                                        <SoftBox mt={2} mb={1} className="col-12 text-end">
                                            <SoftButton variant="gradient" type="submit" color="info">
                                                Submit
                                            </SoftButton>
                                        </SoftBox>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="row flex-wrap">
                        <div className='col-6 p-2'>
                            <div className="setting-input-wrapper">
                                <h5>Social Plugins</h5>
                                <div className='custom-devider'></div>
                                <form id='socialPluginUpdate' ref={socialPluginUpdateRef}>
                                    <input type="hidden" value={Admin_Data.id} name="id" readOnly />
                                    <div className='p-4 d-flex flex-wrap'>
                                        <div className='col-6'>
                                            <SoftBox className="newerror px-2">
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Like
                                                    </SoftTypography>
                                                </SoftBox>
                                                <Select
                                                    name="reaction_like"
                                                    value={data.reaction_like ? data.reaction_like : 0}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={0}>No</MenuItem>
                                                    <MenuItem value={1}>Yes</MenuItem>
                                                </Select>
                                            </SoftBox>
                                        </div>
                                        <div className='col-6'>
                                            <SoftBox className="newerror px-2">
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        GIF
                                                    </SoftTypography>
                                                </SoftBox>
                                                <Select
                                                    name="reaction_gif"
                                                    value={data.reaction_gif ? data.reaction_gif : 0}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={0}>No</MenuItem>
                                                    <MenuItem value={1}>Yes</MenuItem>
                                                </Select>
                                            </SoftBox>
                                        </div>
                                        <div className='col-6'>
                                            <SoftBox className="newerror px-2">
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Download
                                                    </SoftTypography>
                                                </SoftBox>
                                                <Select
                                                    name="reaction_download"
                                                    value={data.reaction_download ? data.reaction_download : 0}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={0}>No</MenuItem>
                                                    <MenuItem value={1}>Yes</MenuItem>
                                                </Select>
                                            </SoftBox>
                                        </div>
                                        <div className='col-6'>
                                            <SoftBox className="newerror px-2">
                                                <SoftBox mb={1} ml={0.5}>
                                                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                        Shop now button visiblity
                                                    </SoftTypography>
                                                </SoftBox>
                                                <Select
                                                    name="shop_now_visiblity"
                                                    value={data.shop_now_visiblity ? data.shop_now_visiblity : 0}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={0}>No</MenuItem>
                                                    <MenuItem value={1}>Yes</MenuItem>
                                                </Select>
                                            </SoftBox>
                                        </div>
                                        <SoftBox mt={2} mb={1} className="col-12 text-end">
                                            <SoftButton variant="gradient" type="submit" color="info">
                                                Submit
                                            </SoftButton>
                                        </SoftBox>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </DashboardLayout>
        </>
    )
}

export default AdminSetting;